import React, { useEffect, useState } from "react";
import { Text } from "../../library/text/text";
import "./filter-results.scss";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_FILTER_RESULT } from "../../shared/graphql/product-queries";
import { PlpProductCard } from "../../library/selectors/plp-product-card/plp-product-card";
import LoadingPage from "../../pages/loading-page/loading-page";

export const FilterResults = ({ defaultCategories, filterItems }) => {
  const filters = {};
  const [resultsCount, setResultsCount] = useState(0);

  useEffect(() => {
    setResultsCount(document.getElementsByClassName("product-card").length);
  }, [filterItems]);

  const onChangeTotalValue = (operationName, count) => {
    if (operationName === "add") {
      setResultsCount(resultsCount + count);
    } else if (operationName === "rest") {
      setResultsCount(resultsCount - count);
    }
  };

  filterItems.forEach((filter) => {
    if (!filters[filter.categoryId]) {
      filters[
        filter.categoryId === "category_uid" ? "category_id" : filter.categoryId
      ] = {};

      if (filter.categoryId === "price") {
        const priceRangeContent = filter.value?.split("_");
        filters[filter.categoryId] = {
          from: priceRangeContent[0],
          to: priceRangeContent[1],
        };
      } else if (filter.categoryId === "category_uid") {
        filters["category_id"].in = filterItems
          .filter((item) => {
            return item.categoryId === filter.categoryId;
          })
          .map((element) => {
            return element.value;
          });
      } else {
        filters[filter.categoryId].in = filterItems
          .filter((item) => {
            return item.categoryId === filter.categoryId;
          })
          .map((element) => {
            return element.value;
          });
      }
    }
  });

  if (!filters["category_id"]) {
    filters["category_id"] = {};
    filters["category_id"].in = defaultCategories;
  }

  const { data: filteredResults } = useQuery(GET_PRODUCT_FILTER_RESULT, {
    variables: {
      filters: filters,
      pageSize: 100,
    },
  });

  return (
    <div className="filter-result">
      {!filteredResults ? (
        <LoadingPage />
      ) : (
        <>
          <Text tag="h2" className="title">
            Results (
            {filteredResults ? filteredResults.products?.total_count : 0})
          </Text>
          {filteredResults && (
            <PlpProductCard
              products={filteredResults.products?.items}
              isResultList
              productType="accessory"
            />
          )}
        </>
      )}
    </div>
  );
};
