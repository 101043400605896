import { gql } from "@apollo/client";

export const GET_ACCESSORIES_PLP = gql`
  query getAccessoriesPLP {
    aem_accessoriesPlpModelList {
      items {
        __typename
        pageHeader
        pageDescription
        categories {
          ... on aem_AccessoriesCategoryModel {
            categoryName
            categoryNumber
          }
        }
      }
    }
  }
`;

export const GET_ACCESSORY_PRODUCT_LISTS = gql`
  query getProductList($catId: String!, $count: Int!) {
    products(
      filter: { category_id: { eq: $catId } }
      pageSize: $count
      currentPage: 1
    ) {
      total_count
      items {
        name
        sku
        os_type
        stock_status
        device_preorder {
          status
          ship_date
          button_text
          note
        }
        manufacturer_data {
          option_id
          option_label
        }
        small_image {
          url
          label
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        url_key
      }
    }
  }
`;

export const GET_ACCESSORIES_BANNER = gql`
  query getAccessoriesBanner {
    aem_accessoriesBannerList {
      items {
        titleContent
        linkContent
        firstImage {
          imageSource
          altText
        }
        secondImage {
          imageSource
          altText
        }
      }
    }
  }
`;

export const GET_ACCESSORY_PRODUCT_BY_SLUG = gql`
  query getProduct($key: String!) {
    products(filter: { url_key: { eq: $key } }) {
      items {
        id
        attribute_set_id
        name
        url_key
        sku
        manufacturer_data {
          option_id
          option_label
          __typename
        }
        swatch_image
        small_image {
          ...productImage
          __typename
        }
        media_gallery {
          ...productImage
          __typename
        }
        __typename
        price_range {
          minimum_price {
            regular_price {
              value
              currency
              __typename
            }
            __typename
          }
          __typename
        }
        categories {
          id
          __typename
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
                __typename
              }
              __typename
            }
            product_id
            __typename
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              device_preorder {
                status
                ship_date
                button_text
                note
                __typename
              }
              media_gallery {
                ...productImage
                __typename
              }
              device_insurance {
                deviceSku
                name
                price
                recurringPrice
                sku
                type
                __typename
              }
              handset_color
              handset_memory_size
              attribute_set_id
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            attributes {
              label
              code
              value_index
              __typename
            }
            __typename
          }
          __typename
        }
        meta_description
        meta_keyword
        meta_title
        short_description {
          html
          __typename
        }
      }
      __typename
    }
  }

  fragment productImage on ProductImage {
    url
    label
    __typename
  }
`;
