import { useEffect } from "react";

function useAsappChat() {
  useEffect(() => {
    ASAPP("load", {
      APIHostname: process.env.REACT_APP_ASAPP_HOSTNAME,
      AppId: process.env.REACT_APP_ASAPP_APP_ID,
      Display: {
        BadgeType: "none",
        FrameStyle: "sidebar",
      },
    });
  }, []);
}

export default useAsappChat;
