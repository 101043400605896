import React, { Component, lazy, Suspense } from "react";

const ErrorPage = lazy(() =>
  import(
    /* webpackChunkName: "error-page" */
    "../../../pages/error-page/error-page"
  )
);
let curUrl = window.location.href;
class PageErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const newURL = window.location.href;

    if (newURL !== curUrl) {
      this.setState({ hasError: false });
      curUrl = newURL;
    }
  }

  componentDidCatch(error, info) {
    console.error(`page level error | ${error} | ${info}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Suspense fallback={<div />}>
          <ErrorPage />
        </Suspense>
      );
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;
