function useAnalytics() {
  function sendFormErrEvent(formName, formErrName) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    const adobeErrObj = {
      event: "form_error",
      form: {
        formDetails: {
          formError: {
            value: 1,
          },
          formName: formName,
          formErrorName: formErrName,
        },
      },
    };
    window.adobeDataLayer.push(adobeErrObj);
  }

  function sendClickEvent(location, eventName, clickURL, pName) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    const adobeClickObj = {
      event: "ui_click",
      web: {
        webInteraction: {
          linkClicks: {
            value: 1,
          },
          clickLocation: location,
          name: eventName,
          clickName: eventName,
          URL: clickURL,
          pageName: pName,
          type: "other",
        },
      },
    };
    window.adobeDataLayer.push(adobeClickObj);
  }

  function sendCampImpEvent() {
    window.adobeDataLayer = window.adobeDataLayer || [];
    const adobeClickObj = {
      event: "internal_camp_impression",
      web: {
        webPageDetails: {
          pageViews: {
            value: 1,
          },
          name: "BMWeb|New Account Redirect",
          domain: "Boost",
          language: "en",
          siteSection: "username",
          siteSubSection: "username_footer",
          siteSubSubSection: "username_footer",
          URL: window.location.href,
          qsp: window.location.search,
          platform: "PING:" + process.env.REACT_APP_ENV + ":Boost",
          type: "login username modal",
        },
      },
    };
    window.adobeDataLayer.push(adobeClickObj);
  }

  function sendScreenLoadEvent(name) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    const adobeClickObj = {
      event: "screen_load",
      web: {
        webPageDetails: {
          pageViews: {
            value: 1,
          },
          name: name,
          domain: location.hostname,
          language: "en",
          siteSection: location.pathname.split("/")[1] || "home",
          siteSubSection: location.pathname.split("/")[2] || "",
          siteSubSubSection: location.pathname.split("/")[3] || "",
          URL: window.location.href,
          qsp: window.location.search,
          platform: "HEADLESS:" + process.env.REACT_APP_ENV + ":boost-infinite",
        },
      },
    };
    window.adobeDataLayer.push(adobeClickObj);
  }
  return {
    sendFormErrEvent,
    sendClickEvent,
    sendCampImpEvent,
    sendScreenLoadEvent,
  };
}

export default useAnalytics;
