import React, { useContext, useState } from "react";
import { Button } from "../button/button";
import { Text } from "../text/text";
import { useAnalytics, useNavigateTo } from "../../shared/hooks/hooks";
import { Link, NavLink } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Logo } from "./logo.svg";
import { AnalyticsContext } from "../../shared/contexts";
import "./dropdown-menu.scss";

export const DropdownMenu = ({ menuItems }) => {
  const navigateTo = useNavigateTo();
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const analytics = useAnalytics();
  const { analyticsInfo } = useContext(AnalyticsContext);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <nav className="mobile-nav">
      <div className="menu">
        <div className="logo">
          <Link
            to="/"
            onClick={() => {
              analytics.sendClickEvent(
                "Header Logo",
                "",
                location.pathname,
                analyticsInfo.pageName
              );
            }}
          >
            <Logo />
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {isOpenMenu ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>
      {isOpenMenu && (
        <div className="open-menu">
          <ul className="menu-items">
            {menuItems?.map((item, idx) => (
              <NavLink
                key={idx}
                to={item.url}
                target={item.openNewTab ? "_blank" : ""}
                onClick={() => {
                  analytics.sendClickEvent(
                    "Header",
                    item.name,
                    location.pathname,
                    analyticsInfo.pageName
                  );
                }}
              >
                <Text tag="body" bold>
                  {item.name}
                </Text>
              </NavLink>
            ))}
          </ul>
          <Button
            className="menu-button"
            onClick={() => navigateTo("/get-started")}
          >
            <Text tag="body" bold>
              Get Started
            </Text>
            <EastIcon />
          </Button>
        </div>
      )}
    </nav>
  );
};
