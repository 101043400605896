import { useEffect, useState, useRef, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router";
import { useOptimisticData } from "../hooks";
import {
  GET_PRODUCT_BY_SLUG,
  GET_BOOST_PROTECT,
} from "../../graphql/product-queries";
import { isNotEmptyNullOrUndefined } from "../../utils/validators";

export function useProduct() {
  const [isLoadingProduct, setisLoadingProduct] = useState(true);
  const [error, setError] = useState();
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const client = useApolloClient();

  const urlParams = useParams();
  const optimisticData = useOptimisticData();
  const isInitializedRef = useRef(false);

  const [product, setProduct] = useState(
    optimisticData.read("product") || null
  );

  const [boostProtect, setBoostProtect] = useState(
    optimisticData.read("boostprotect") || null
  );

  const [shouldFetchData, setShouldFetchData] = useState(
    isNotEmptyNullOrUndefined(product)
  );
  const shouldFetchProduct = isRefreshingData || shouldFetchData;
  const { productSlug } = urlParams;
  const getProduct = useCallback(
    async (productKey) => {
      try {
        setisLoadingProduct(true);
        setShouldFetchData(false);

        const { data: product, errors } = await client.query({
          query: GET_PRODUCT_BY_SLUG,
          variables: { key: productKey },
        });

        if (Array.isArray(errors)) {
          throw errors?.[0] || "no product";
        }

        const { data: boostprotect, boostprotecterrors } = await client.query({
          query: GET_BOOST_PROTECT,
          variables: { sku: product?.products?.items[0]?.sku },
        });

        setProduct(product);
        if (Array.isArray(boostprotecterrors)) {
          throw errors?.[0] || "boost protect api issue";
        }
        setBoostProtect(boostprotect);
      } catch (err) {
        setError(`No product found | ${err?.message || err}`);
      } finally {
        setisLoadingProduct(false);
        setIsRefreshingData(false);
      }
    },
    [client]
  );

  useEffect(() => {
    if (shouldFetchProduct) {
      getProduct(productSlug);
    }
  }, [shouldFetchProduct, getProduct]);

  useEffect(() => {
    if (!isInitializedRef.current) {
      getProduct(productSlug);
      isInitializedRef.current = true;
    }
  }, [getProduct]);

  return {
    error,
    isLoadingProduct,
    isRefreshingData,
    product,
    boostProtect,
  };
}

export default useProduct;
