import PropTypes from "prop-types";
import { useNavigateTo } from "../../shared/hooks/hooks";
import West from "@mui/icons-material/West";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Button } from "../button/button";
import { Text } from "../../library/text/text";
import useMediaQuery from "../../shared/hooks/use-media-query/use-media-query";
import "./buyflow-header-bar.scss";

export const BuyflowHeaderBar = ({ lineText, disableNavBack, ...props }) => {
  const navigateTo = useNavigateTo();
  const breakpoint = useMediaQuery();

  function handleAsapp() {
    window.ASAPP("getState", function (state) {
      if (state.isOpen) {
        window.ASAPP("hide");
      } else {
        window.ASAPP("show");
      }
    });
  }

  return (
    <div className="buy-header">
      {!disableNavBack ? (
        <Button
          type="ghost"
          onClick={() => {
            navigateTo(-1);
          }}
        >
          <West />
          {breakpoint.isMinMd && (
            <Text tag="body" bold>
              Back
            </Text>
          )}
        </Button>
      ) : (
        <div></div>
      )}

      <Text tag="body" bold>
        {lineText}
      </Text>
      <Button type="ghost" onClick={() => handleAsapp()}>
        <ChatBubbleOutlineIcon />
      </Button>
    </div>
  );
};
BuyflowHeaderBar.propTypes = {
  lineText: PropTypes.string,
};
