import gql from "graphql-tag";
import { cartItems } from "./cart-queries";

const configurableOptions = gql`
  fragment configurableOptions on SelectedConfigurableOption {
    option_label
    value_label
    value_id
  }
`;

const boostProtectOption = gql`
  fragment boostProtectOption on BoostProtectOption {
    status
    product_name
    product_sku
    price
  }
`;

const financeOption = gql`
  fragment financeOption on FinanceOption {
    status
    price
    display_name
  }
`;

const billingAddressFragment = gql`
  fragment billingAddressFragment on BillingCartAddress {
    firstname
    lastname
    street
    city
    postcode
    country {
      code
      label
    }
    region {
      code
      label
      region_id
    }
    telephone
  }
`;

const shippingAddressFragment = gql`
  fragment shippingAddressFragment on ShippingCartAddress {
    firstname
    lastname
    street
    city
    postcode
    country {
      code
      label
    }
    region {
      code
      label
      region_id
    }
    telephone
  }
`;

const cartItem = gql`
  fragment cartItem on CartItemInterface {
    id
    package_id
    display_product_type
    display_name
    recurring_price
    finance_option {
      ...financeOption
    }
    simple_product_sku
    manufacturer_data {
      option_id
      option_label
    }
    product_image
    product {
      name
      sku
      url_key
      price_range {
        minimum_price {
          final_price {
            value
            currency
          }
        }
      }
    }
    ... on ConfigurableCartItem {
      configurable_options {
        ...configurableOptions
      }
      boost_protect_option {
        ...boostProtectOption
      }
    }
    quantity
    uid
  }
  ${financeOption}
  ${boostProtectOption}
  ${configurableOptions}
`;

export const CREATE_CART = gql`
  mutation {
    createEmptyCart
  }
`;

export const ADD_PRODUCT_TO_CART = gql`
  mutation addToCart($input: AddConfigurableProductsToCartInput) {
    addConfigurableProductsToCart(input: $input) {
      cart {
        ...cartItems
      }
    }
  }
  ${cartItems}
`;

export const UPDATE_CART_ITEMS = gql`
  mutation updateCartItems($input: UpdateCartItemsInput) {
    updateCartItems(input: $input) {
      cart {
        ...cartItems
      }
    }
  }
  ${cartItems}
`;

export const REMOVE_CART_ITEM = gql`
  query deleteItem($package_id: String!, $cartId: String!) {
    getDeviceDelete(package_id: $package_id) {
      package_id
      message
    }
    cart(cart_id: $cartId) {
      ...cartItems
    }
  }
  ${cartItems}
`;
