import React from "react";
import PropTypes from "prop-types";
import { Link as CarbonLink } from "@carbon/react";
import "./link.scss";

export const Link = ({ isDisabled, href, children, onClick, ...props }) => {
  const currentLink = document.getElementById("linkTarget");

  const handleClick = (e) => {
    currentLink?.classList?.add("link-visited");
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <CarbonLink
      id="linkTarget"
      disabled={isDisabled}
      href={href}
      className="link"
      onClick={handleClick}
      target={/http:\/\/|https:\/\/|www\./.test(href) ? "_blank" : "_self"}
      {...props}
    >
      {children}
    </CarbonLink>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.element,
};
