import React, { useEffect, useState } from "react";
import { localStorage } from "../../../../shared/utils/storage";
import { CART_SUMMARY } from "../../../../shared/constants/storage-keys";
import { useCartAPI } from "../api/useCartAPI";

export const DishCartContext = React.createContext();

const DishCartProvider = (props) => {
  const { getCart } = useCartAPI();

  const [cartContext, setCartContext] = useState({
    cart: null,
    isCartLoading: true,
    refreshCart: async () => {
      await executeGetCart();
    },
    clearCart: () => {
      clearCart();
    },
  });

  const clearCart = () => {
    setCartContext({
      ...cartContext,
      cart: null,
    });
  };

  const executeGetCart = async () => {
    const localCart = localStorage(CART_SUMMARY);
    const cartId = localCart?.id;

    if (!cartId) {
      setCartContext({
        ...cartContext,
        isCartLoading: false,
      });
      return; // Exit if no cartId
    }

    try {
      setCartContext({
        ...cartContext,
        isCartLoading: true,
      });

      const result = await getCart(cartId);

      const updatedCart = result?.data?.ms_getCart;

      setCartContext({
        ...cartContext,
        cart: updatedCart,
        isCartLoading: false,
      });
    } catch (error) {
      console.log(`Error executing Get Cart with ID ${cartId}: ${error}`);

      setCartContext({
        ...cartContext,
        isCartLoading: false,
      });
    }
  };

  useEffect(() => {
    executeGetCart();
  }, []);

  return (
    <DishCartContext.Provider value={[cartContext, setCartContext]}>
      {props.children}
    </DishCartContext.Provider>
  );
};

export default DishCartProvider;
