import gql from "graphql-tag";

const ADDRESS = `
  city
  country
  firstName
  lastName
  street1
  street2
  state
  zipCode
  phone
`;

export const CART = `
  errors {
    code
    message
    type
  }
  email
  billingAddress {
      ${ADDRESS}
    }
    dueTodayItems {
      name
      price{
        value
        currency
      }
      type
    }
    taxes{
      label
      amount {
        value
        currency
      }
    }
    dueTodayTotal {
      currency
      value
    }
    grandTotal {
      currency
      value
    }
    id
    items {
      isLoanItem
      isBackorder
      isPreorder
      backorderExpectedShipDate
      deviceData{
        boost_protect_option{
          price
          product_name
          product_sku
          status
        }
        configurable_options{
          option_label
          value_id
          value_label
        }
        finance_option{
          discount
          display_name
          price
          status
        }
        display_name
        display_product_type
        id
        package_id
        manufacturer_data{
          option_id
          option_label
        }
        prices{
          discounts{
            value
            currency
          }
          price{
            value
            currency
          }
          total_item_discount{
            value
            currency
          }
        }
        product{
          name
          sku
          url_key
          price_range{
            minimum_price{
              final_price{
                value
                currency
              }
            }
          }
        }
        product_image
        quantity
        recurring_price
        simple_product_sku
        uid
        byod_device_data {
            title
            image
        }
        trade_in_data {
          imei
          offer_id
        }
      }
      accessoryData {
        configurable_options {
          option_label
          value_id
          value_label
        }
        display_name
        id
        prices {
          discounts {
            value
            currency
          }
          price {
            value
            currency
          }
          total_item_discount {
            value
            currency
          }
        }
        product_image
        quantity
        simple_product_sku
        byod_device_data {
            title
            image
        }
        trade_in_data {
          imei
          offer_id
        }
      }
      deviceInsurance{
        configurable_options{
          option_label
          value_id
          value_label
        }
        finance_option{
          discount
          display_name
          price
          status
        }
        display_name
        display_product_type
        id
        package_id
        manufacturer_data{
          option_id
          option_label
        }
        prices{
          discounts{
            value
            currency
          }
          price{
            value
            currency
          }
          total_item_discount{
            value
            currency
          }
        }
        product{
          name
          sku
          url_key
          price_range{
            minimum_price{
              final_price{
                value
                currency
              }
            }
          }
        }
        product_image
        quantity
        recurring_price
        simple_product_sku
        uid
      }
      devicePlan
      lineNumber
      phoneName
      planData{
        configurable_options{
          option_label
          value_id
          value_label
        }
        finance_option{
          discount
          display_name
          price
          status
        }
        display_name
        display_product_type
        id
        package_id
        manufacturer_data{
          option_id
          option_label
        }
        prices{
          discounts{
            value
            currency
          }
          price{
            value
            currency
          }
          total_item_discount{
            value
            currency
          }
        }
        product{
          name
          sku
          url_key
          price_range{
            minimum_price{
              final_price{
                value
                currency
              }
            }
          }
        }
        product_image
        quantity
        recurring_price
        simple_product_sku
        uid
        byod_device_data {
            title
            image
        }
        trade_in_data {
          imei
          offer_id
        }
      }
      simData{
        configurable_options{
          option_label
          value_id
          value_label
        }
        finance_option{
          discount
          display_name
          price
          status
        }
        display_name
        display_product_type
        id
        package_id
        manufacturer_data{
          option_id
          option_label
        }
        prices{
          discounts{
            value
            currency
          }
          price{
            value
            currency
          }
          total_item_discount{
            value
            currency
          }
        }
        product{
          name
          sku
          url_key
          price_range{
            minimum_price{
              final_price{
                value
                currency
              }
            }
          }
        }
        product_image
        quantity
        recurring_price
        simple_product_sku
        uid
        byod_device_data {
            title
            image
        }
        trade_in_data {
          imei
          offer_id
        }
      }
      tradeInKit {
        display_name
        display_product_type
        id
        package_id
        product_image
        quantity
        recurring_price
        simple_product_sku
        uid
        trade_in_data {
          imei
          offer_id
        }
      }
    }
    monthlyTotal
    numItems
    qualificationStatus {
      status
      contentIdentifier
    }
    hasLoanItems
    hasPreorderItems
    shippingAddress {
      ${ADDRESS}
    }
`;

const PAYMENT_PROVIDERS = `
  paymentProviders
    {
      amazonPay{
        algorithm
        estimatedOrderAmount{
          amount
          currencyCode
        }
        ledgerCurrency
        merchantId
        payloadJSON
        productType
        publicKeyId
        sandbox
        signature
      }
      cybersource{
        session
      }
    }
`;

export const getCart = gql`
    query getCartByID($cartId: String!){
      ms_getCart(cartId: $cartId) {
            ${CART}
        }
    }
`;

export const precheckout = gql`
    mutation precheckout($cartId:String!, $redirectURL:URL!) {
        ms_cartPrecheckout(input: {cartId:$cartId, redirectURL:$redirectURL}) {
            ${CART}
            ${PAYMENT_PROVIDERS}
        }
    }
`;

export const checkout = gql`
    mutation checkout($input:ms_CheckoutRequest_Input!) {
        ms_cartCheckout(input: $input) {
          cart{
            ${CART}
          }
          order{
            orderId
            payment{
              type
              card{
                type
                number
                expiryMonth
                expiryYear
              }
            }
          }
        }
    }
`;

export const checkQualification = gql`
    mutation qual($cartId:String!, $email:EmailAddress!, $firstName:String!, $lastName: String!, $addressData:ms_AddressInput_Input, $ssn:String!) {
      ms_getQualification(
        input: {
          cartId: $cartId,
          email:$email,
          firstName: $firstName,
          lastName: $lastName,
          addressData: $addressData,
          ssn: $ssn
        }
      ){
        ${CART}
      }
    }
`;

export const getLoanDocs = gql`
  query getLoanDocsByCart($cartId: String!) {
    ms_getLoanDocuments(cartId: $cartId) {
      agreementNumber
      billingAccountId
      id
      loans {
        id
        deviceName
        documents {
          type
          name
          file
        }
      }
    }
  }
`;

export const updateShippingAddress = gql`
  mutation updateShippingAddress($cartId: String!, $addressData:ms_AddressInput_Input, $firstName: String!, $lastName: String!, $doPrecheckout:Boolean, $redirectURL:String){
    ms_setShippingAddress(
      input: {
        address: {addressData: $addressData, firstName: $firstName, lastName: $lastName }
        cartId: $cartId
        doPrecheckout: $doPrecheckout,
        redirectURL: $redirectURL
      }
    ) {
      ${CART}
      ${PAYMENT_PROVIDERS}

    }
  }
`;

export const addDeviceInsurance = gql`
  mutation addDeviceInsurance($cartId: String!, $packageId: Int!){
    ms_addDeviceInsurance(
      input: {
        cartId: $cartId
        packageId: $packageId
      }
    ) {
      ${CART}
    }
  }
`;