import { useEffect, useCallback, useRef } from "react";

function useScrollTo(id, options, optionalElem) {
  let config = {
    initialScrollDelay: 750,
    offset: 0,
  };

  if (options && typeof options === "object") {
    config = {
      ...config,
      ...options,
    };
  }
  const configRef = useRef(config);
  const hash = window.location.hash;
  const path = window.location.pathname;
  const scroll = useCallback(() => {
    const config = configRef.current;
    const elem = document.querySelector(id) || optionalElem;
    const top = elem
      ? elem.getBoundingClientRect().top + (window.scrollY - config.offset)
      : null;

    if (top) {
      window.scrollTo({
        top,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [id, optionalElem]);

  useEffect(() => {
    const config = configRef.current;
    if (hash === id) {
      setTimeout(() => {
        scroll();
      }, config.initialScrollDelay);
    }
  }, [hash, id, path, scroll]);

  return scroll;
}

export default useScrollTo;
