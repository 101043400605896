import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../layouts/default-layout/default-layout";
import Page from "../../library/page/page";
import { Text } from "../../library/text/text";
import { Button } from "../../library/button/button";
import { useQuery } from "@apollo/client";
import { GET_ACCESSORIES_PLP } from "../../shared/graphql/accessories-queries";
import AccessoriesFilterModal from "../../components/accessories-filter-modal/accessories-filter-modal";
import { RouteConfig } from "../../v2/routes/route.config";
import { FilterResults } from "../../components/filter-results/filter-results";
import { useCart, useNavigateTo } from "../../shared/hooks/hooks";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./accessories-results-page.scss";
import { useLocation } from "react-router";
import useFeatureFlag from "../../shared/hooks/use-feature-flag/use-feature-flag";

function AccessoriesResultsPage() {
  const { data: fetchedPageData } = useQuery(GET_ACCESSORIES_PLP, {});
  const pageData = fetchedPageData?.aem_accessoriesPlpModelList?.items[0];
  const [moreFilterItems, setMoreFilterItems] = useState([]);
  const [myDevicesItems, setMyDevicesItems] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const navigateTo = useNavigateTo();
  const { cart } = useCart();
  const location = useLocation();
  const didMount = useRef(false);

  const handleMoreFilterSelection = (moreFilterItems) => {
    setMoreFilterItems(moreFilterItems);
  };

  const handleMyDevicesFilterSelection = (myDevicesItems) => {
    setMyDevicesItems(myDevicesItems);
  };

  const handleAddCategoryList = (categoryList) => {
    setDefaultCategories(categoryList);
  };

  let myDevicesFilterList;
  const uniqueElements = [];

  if (cart) {
    myDevicesFilterList = cart?.items.filter((item) => {
      if (
        item.display_product_type === "Mobile Device" &&
        item.simple_product_sku !== "BYODSKU" &&
        !uniqueElements.includes(item.display_name)
      ) {
        uniqueElements.push(item.display_name);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    location.state?.moreFilterItems &&
      setMoreFilterItems(location.state.moreFilterItems);
    location.state?.myDevicesItems &&
      setMyDevicesItems(location.state.myDevicesItems);
    location.state?.defaultCategories &&
      setDefaultCategories(location.state?.defaultCategories);
  }, []);

  useEffect(() => {
    if (didMount.current && !moreFilterItems.length && !myDevicesItems.length) {
      navigateTo("/accessories");
    } else if (!didMount.current) {
      didMount.current = true;
    }
  }, [moreFilterItems, myDevicesItems]);

  const { getAccessoriesFlag } = useFeatureFlag();
  const accessoriesFlag = getAccessoriesFlag();

  return (
    <>
      {accessoriesFlag && (
        <DefaultLayout isBuyFlow buyFlowHeaderText="Accessories">
          <Page className="accessories-plp-page">
            <div className="plp-header-container">
              <Text tag="h1" className="plp-header">
                {pageData?.pageHeader}
              </Text>
              <Text tag="h2" className="plp-sub-header">
                {pageData?.pageDescription}
              </Text>
            </div>
            <div className="plp-filter-selector-container">
              <AccessoriesFilterModal
                emptyCart={!(myDevicesFilterList?.length > 0)}
                myDevicesFilterList={myDevicesFilterList}
                moreFilterChange={handleMoreFilterSelection}
                defaultCategoriesAdded={handleAddCategoryList}
                myDevicesFilterChange={handleMyDevicesFilterSelection}
              />
            </div>
            <div className="results-section">
              <FilterResults
                defaultCategories={defaultCategories}
                filterItems={[...moreFilterItems, ...myDevicesItems]}
              ></FilterResults>
            </div>

            {cart?.items?.length > 0 && (
              <div className="cart-button__wrapper">
                <Button
                  className="cart-button"
                  onClick={() => navigateTo("/order-summary")}
                >
                  <Text tag="body" bold>
                    Continue to Cart
                  </Text>
                  <ArrowForwardIcon />
                </Button>
              </div>
            )}
          </Page>
        </DefaultLayout>
      )}
    </>
  );
}

export default AccessoriesResultsPage;
