import { PASS_CONTACT_INFO, PASS_USER_INFO } from "../../graphql/fraud-queries";
import { useApolloClient } from "@apollo/client";
import { sessionStorage } from "../../utils/storage";
import { UBF } from "../../constants/storage-keys";

function useFraud() {
  const client = useApolloClient();

  /**
   * Pass contact info hook
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} shippingAddress
   * @param {object} billingAddress
   * @param {string} phoneNumber
   */
  async function passContactInfo(
      firstName,
      lastName,
      shippingAddress,
      billingAddress,
      phoneNumber = ''
  ) {
    const mutationVariables = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
    };
    console.log('passContactInfo mutationVariables: ');
    console.log(mutationVariables);
    await client.mutate({
      mutation: PASS_CONTACT_INFO,
      variables: mutationVariables,
    });
  }

  async function passUserInfo() {
    const ubf = getBrowserFingerprint();
    let ipResult = await fetch("https://api.ipify.org/?format=json");
    const { ip: clientIp } = (ipResult = await ipResult.json());

    const checkUbf = `${ubf}/${clientIp}`;

    if (checkUbf !== sessionStorage("UBF")) {
      sessionStorage(UBF, checkUbf);

      const response = await client.mutate({
        mutation: PASS_USER_INFO,
        variables: {
          ubf: ubf,
          clientIp: clientIp,
        },
      });

      return response;
    }
  }

  function getBrowserFingerprint() {
    function getMarketingIdPart1() {
      return (
        getBrowserIdentifier() +
        getTimezoneOffsetString() +
        getBrowserLanguage() +
        getDeviceIdentifier() +
        isShoppingTimeCookieOldOrNew()
      );
    }

    function getMarketingIdPart2() {
      return getShoppingTimeCookie();
    }

    function getMarketingIdPart3() {
      let marketingIDstr = getHashCode(getMarketingId());
      let marketingIDstr12 = marketingIDstr.substring(0, 12);
      while (marketingIDstr12.length < 12) {
        marketingIDstr12 = "0" + marketingIDstr12;
      }
      return marketingIDstr12.replace(/-/g, "A");
    }

    function getBrowserExtensionList() {
      let pluginstr;
      let pluginlen = navigator.plugins.length;
      for (let i = 0; i < pluginlen; i++) {
        pluginstr += navigator.plugins[i].name;
      }
      return pluginstr;
    }

    function getMarketingId() {
      return (
        navigator.userAgent +
        screen.width +
        screen.height +
        navigator.platform +
        navigator.cpuClass +
        getBrowserExtensionList()
      );
    }

    function getBrowserIdentifier() {
      let ua = navigator.userAgent;
      let browserChar = "U";
      if (ua.includes("Firefox")) {
        browserChar = "F";
      } else if (ua.includes("SamsungBrowser")) {
        browserChar = "B";
      } else if (ua.includes("Opera") || ua.includes("OPR")) {
        browserChar = "O";
      } else if (ua.includes("Edge")) {
        browserChar = "E";
      } else if (ua.includes("Edg")) {
        browserChar = "C";
      } else if (ua.includes("Chrome")) {
        browserChar = "C";
      } else if (ua.includes("Safari")) {
        browserChar = "S";
      }
      return browserChar;
    }

    function getTimezoneOffsetString() {
      let date = new Date(Date.UTC(2016, 12, 30, 0, 0, 0, 0));
      var timezoneOffset = (-1 * date.getTimezoneOffset()).toString();
      while (timezoneOffset.length < 2) {
        timezoneOffset = "0" + timezoneOffset;
      }
      if (!timezoneOffset.match(/-/)) {
        timezoneOffset = "+" + timezoneOffset;
      }
      return timezoneOffset;
    }

    function getBrowserLanguage() {
      let localeCode = navigator.systemLanguage || window.navigator.language;
      return localeCode.slice(0, 2).toUpperCase();
    }

    function getDeviceIdentifier() {
      let isTouchDevice = "ontouchstart" in document.documentElement;

      let height = screen.height;
      let width = screen.width;
      let ratio = height > width ? height / width : width / height;
      ratio = (10 * Number(ratio)).toFixed(4);

      let screenRatioAsChar;
      if (ratio < 10) {
        screenRatioAsChar = "a";
      } else if (ratio < 12.5) {
        screenRatioAsChar = "b";
      } else if (ratio < 13.2813) {
        screenRatioAsChar = "c";
      } else if (ratio < 13.3333) {
        screenRatioAsChar = "d";
      } else if (ratio < 13.3398) {
        screenRatioAsChar = "e";
      } else if (ratio < 14.0625) {
        screenRatioAsChar = "f";
      } else if (ratio < 15.0) {
        screenRatioAsChar = "g";
      } else if (ratio < 15.9934) {
        screenRatioAsChar = "h";
      } else if (ratio < 16.0) {
        screenRatioAsChar = "i";
      } else if (ratio < 16.6111) {
        screenRatioAsChar = "j";
      } else if (ratio < 16.6563) {
        screenRatioAsChar = "k";
      } else if (ratio < 16.6667) {
        screenRatioAsChar = "l";
      } else if (ratio < 16.7476) {
        screenRatioAsChar = "m";
      } else if (ratio < 17.0667) {
        screenRatioAsChar = "n";
      } else if (ratio < 17.75) {
        screenRatioAsChar = "o";
      } else if (ratio < 17.7778) {
        screenRatioAsChar = "p";
      } else if (ratio < 17.7865) {
        screenRatioAsChar = "q";
      } else if (ratio < 17.7867) {
        screenRatioAsChar = "r";
      } else if (ratio < 17.8205) {
        screenRatioAsChar = "s";
      } else if (ratio < 18.963) {
        screenRatioAsChar = "t";
      } else if (ratio <= 18.963) {
        screenRatioAsChar = "u";
      } else {
        screenRatioAsChar = "v";
      }
      if (isTouchDevice > 0) {
        screenRatioAsChar = screenRatioAsChar.toUpperCase();
      } else {
        screenRatioAsChar = screenRatioAsChar.toLowerCase();
      }
      return screenRatioAsChar;
    }

    function isShoppingTimeCookieOldOrNew() {
      if (getShoppingTimeCookie()) {
        return "O";
      }
      setShoppingTimeCookie(getCurrentTimeStampCharCodes());
      return "N";
    }

    function setShoppingTimeCookie(value, expDays = 3650) {
      let date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + date.toUTCString() + "; ";
      document.cookie = "ShoppingTime=" + value + "; " + expires + "path=/";
    }

    function getCurrentTimeStampCharCodes() {
      let today = new Date();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();
      let yy = Number(new Date().getFullYear().toString().slice(-2));
      let hh = today.getHours();
      let min = today.getMinutes();
      let ss = today.getSeconds();

      let dayCode = dd <= 26 ? 64 + dd : 71 + dd;
      let minCode, secCode;

      if (min >= 0 && min <= 9) {
        minCode = 48 + min;
      } else if (min >= 10 && min <= 35) {
        minCode = 55 + min;
      } else {
        minCode = 61 + min;
      }

      if (ss >= 0 && ss <= 9) {
        secCode = 48 + ss;
      } else if (ss >= 10 && ss <= 35) {
        secCode = 55 + ss;
      } else {
        secCode = 61 + ss;
      }

      let monthChar = String.fromCharCode(mm + 64);
      let dayChar = String.fromCharCode(dayCode);
      let yearChar = String.fromCharCode(yy + 49);
      let hourChar = String.fromCharCode(hh + 65);
      let minChar = String.fromCharCode(minCode);
      let secChar = String.fromCharCode(secCode);

      return monthChar + dayChar + yearChar + hourChar + minChar + secChar;
    }

    function getShoppingTimeCookie() {
      const cookieName = "ShoppingTime=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let allCookies = decodedCookie.split(";");
      for (let i = 0; i < allCookies.length; i++) {
        let c = allCookies[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(cookieName) === 0) {
          return c.substring(cookieName.length, c.length);
        }
      }
      return "";
    }

    function getHashCode(value) {
      let hash = 0;
      let char;
      if (value.length === 0) {
        return hash;
      }
      for (let i = 0; i < value.length; i++) {
        char = value.charCodeAt(i);
        hash = (hash << 5) - hash + char;
      }
      return hash.toString();
    }
    return (
      getMarketingIdPart1() +
      "|" +
      getMarketingIdPart2() +
      "|" +
      getMarketingIdPart3()
    );
  }

  return {
    passUserInfo,
    getBrowserFingerprint,
    passContactInfo,
  };
}

export default useFraud;
