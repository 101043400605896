export const HomePage = () => import("../../pages/home-page/home-page");
export const PdpPage = () => import("../../pages/pdp-page/pdp-page");
export const GetStartedPage = () =>
  import("../../pages/get-started-page/get-started-page");
export const PlpPage = () => import("../../pages/plp-page/plp-page");
export const PlansPage = () => import("../../pages/plans-page/plans-page");
export const PromoPage = () => import("../../pages/promo-page/promo-page");
export const PlanDetailPage = () =>
  import("../../pages/plan-detail-page/plan-detail-page");
export const ChangePlanPage = () =>
  import("../../pages/change-plan-page/change-plan-page");
export const OrderSummaryPage = () =>
  import("../../pages/order-summary-page/order-summary-page");
export const PhoneCompatibility = () =>
  import("../../pages/phone-compatibility/phone-compatibility");
export const KeepYourNumber = () =>
  import("../../pages/keep-your-number/keep-your-number");
export const NotCompatible = () =>
  import("../../pages/not-compatible/not-compatible");
export const TradeInPage = () =>
  import("../../pages/trade-in-page/trade-in-page");
export const CheckoutPage = () =>
  import("../../v2/features/cart-checkout/pages/review/CheckoutPage");
export const NotFound = () => import("../../pages/error-page/error-page");
export const AboutPage = () => import("../../pages/about-page/about-page");
export const Maintenance = () =>
  import("../../pages/maintenance-page/maintenance-page");
export const AccessoriesPlpPage = () =>
  import("../../pages/accessories-plp-page/accessories-plp-page");
export const AccessoryPdpPage = () =>
  import("../../pages/accessory-pdp-page/accessory-pdp-page");
export const AccessoryResultsPage = () =>
  import("../../pages/accessories-results-page/accessories-results-page");
