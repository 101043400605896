import React, { useEffect, useState } from "react";
import { Checkbox } from "../../library/checkbox/checkbox";
import { Text } from "../../library/text/text";
import "./filter-checkbox.scss";

export const FilterCheckbox = ({
  categoryId,
  checked,
  handleInputChange,
  id,
  label,
  name,
  reset,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (reset) {
      setIsChecked(false);
    }

    if (checked) {
      setIsChecked(true);
    }
  }, [reset, checked]);

  const handleChange = (e) => {
    setIsChecked((checked) => !checked);
    handleInputChange(e, name, categoryId, false);
  };

  const checkboxText = label ? <Text tag="body">{label}</Text> : "";
  //TODO: find out how we can use just a single filter component
  return (
    <div className="checkbox-wrapper">
      <Checkbox
        isChecked={isChecked}
        onChange={handleChange}
        id={id}
        labelText={checkboxText}
      />
    </div>
  );
};
