import { gql } from "@apollo/client";

const productImage = gql`
  fragment productImage on ProductImage {
    url
    label
  }
`;

const financeOptions = gql`
  fragment financeOptions on GetDeviceFinance {
    status
    monthly_price
    duration
    plan_sku
    plan_name
    discount
  }
`;

const devicePlan = gql`
  fragment devicePlan on GetDevicePlan {
    plan_sku
    plan_name
    price
    short_description
    is_default
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetail($key: String!) {
    products(filter: { url_key: { eq: $key } }) {
      items {
        id
        name
        sku
        short_description {
          html
        }
        features_managed
        features_catalog
        snapshot_specification
        specifications
        features_catalog
        whats_included
        full_legal_content
      }
    }
  }
`;

export const GET_PRODUCT_FILTERS = gql`
  query getProductFiltersByCategory($categoryIdFilter: FilterEqualTypeInput!) {
    products(filter: { category_id: $categoryIdFilter }) {
      aggregations {
        label
        count
        attribute_code
        options {
          label
          value
        }
        position
      }
    }
  }
`;

export const GET_PRODUCT_BY_SLUG = gql`
  query getProduct($key: String!) {
    products(filter: { url_key: { eq: $key } }) {
      items {
        id
        attribute_set_id
        name
        url_key
        sku
        device_finance {
          ...financeOptions
        }
        device_plan {
          ...devicePlan
        }
        manufacturer_data {
          option_id
          option_label
        }
        swatch_image
        small_image {
          ...productImage
        }
        media_gallery {
          ...productImage
        }
        __typename
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        categories {
          id
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
              }
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              device_preorder {
                status
                ship_date
                button_text
                note
                backorder_status
                backorder_qty
                backorder_estimated_ship_date
              }
              device_finance {
                ...financeOptions
              }
              media_gallery {
                ...productImage
              }
              handset_color
              handset_memory_size
              attribute_set_id
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
        meta_description
        meta_keyword
        meta_title
        short_description {
          html
        }
        url_path
        url_key
        additional_seo_data {
          meta_robots
          canonical_url
        }
      }
    }
  }
  ${productImage}
  ${financeOptions}
  ${devicePlan}
`;

export const GET_PRODUCT_FILTER_RESULT = gql`
  query getProductList(
    $pageSize: Int!
    $filters: ProductAttributeFilterInput!
  ) {
    products(
      pageSize: $pageSize
      currentPage: 1
      filter: $filters
      sort: { position: ASC }
    ) {
      total_count
      items {
        name
        sku
        stock_status
        url_key
        manufacturer_data {
          option_id
          option_label
        }
        small_image {
          url
          label
          position
          disabled
        }
        thumbnail {
          url
          label
          position
          disabled
        }
        image {
          url
          label
          position
          disabled
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_BOOST_PROTECT = gql`
  query getDeviceInsurance($sku: [String!]!) {
    getDeviceInsurance(sku: $sku) {
      deviceSku
      name
      price
      recurringPrice
      sku
      type
    }
  }
`;
