import { useMediaQuery } from "../hooks/hooks";
import { isNotEmptyNullOrUndefined } from "./validators";

export function getCmsImage(mobileImage, desktopImage) {
  const breakpoint = useMediaQuery();
  if (
    !isNotEmptyNullOrUndefined(mobileImage) &&
    !isNotEmptyNullOrUndefined(desktopImage)
  ) {
    return "";
  } else if (!isNotEmptyNullOrUndefined(mobileImage)) {
    return desktopImage;
  } else if (!isNotEmptyNullOrUndefined(desktopImage)) {
    return mobileImage;
  }
  return breakpoint.isMinMd ? desktopImage : mobileImage;
}

export function fixProductImage(url) {
  if (!isNotEmptyNullOrUndefined(url) || /alpha/.test(url)) {
    return url;
  }
  if (/webp/.test(url)) {
    return `${url}-alpha`;
  } else {
    if (/\?/.test(url)) {
      return `${url}&fmt=webp-alpha`;
    } else {
      return `${url}?fmt=webp-alpha`;
    }
  }
}
