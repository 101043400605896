function useFeatureFlag() {
  /**
   * If true accessories should be displayed.
   * Defaults to false.
   * @returns {boolean}
   */
  function getAccessoriesFlag() {
    if (process.env.REACT_APP_FEATURE_FLAG_ACCESSORIES === "true") {
      return true;
    }
    return false;
  }

  /**
   * If true fraud features should be active.
   * Defaults to false.
   * @returns {boolean}
   */
  function getFraudFlag() {
    if (process.env.REACT_APP_FEATURE_FLAG_FRAUD === "true") {
      return true;
    }
    return false;
  }

  /**
   * If true maintenance mode should be active.
   * Defaults to false.
   * @returns {boolean}
   */
  function getMaintenanceModeFlag() {
    if (process.env.REACT_APP_FEATURE_FLAG_MAINTENANCE_MODE === "true") {
      return true;
    }
    return false;
  }

  return { getAccessoriesFlag, getFraudFlag, getMaintenanceModeFlag };
}

export default useFeatureFlag;
