import React from "react";
import PropTypes from "prop-types";
import "./text.scss";

const TAG_TYPES = {
  display: "display",
  h1: "h1",
  h2: "h2",
  body: "body",
  small: "small",
};

export const Text = ({ tag, className, bold = false, children, ...props }) => {
  let styling = "";

  if (tag === "display") {
    tag = "h1";
    styling = "display";
  } else if (tag === "body") {
    tag = "p";
    styling = "body";
  } else if (tag === "small") {
    tag = "p";
    styling = "small";
  }

  const element = React.createElement(
    tag,
    {
      ...(styling || bold || className
        ? {
            className: `${styling}${bold ? " bold" : ""}${
              className ? ` ${className}` : ""
            }`,
          }
        : null),
      ...props,
    },
    children
  );

  return element;
};

Text.propTypes = {
  tag: PropTypes.oneOf([
    TAG_TYPES.display,
    TAG_TYPES.h1,
    TAG_TYPES.h2,
    TAG_TYPES.body,
    TAG_TYPES.small,
  ]).isRequired,
};
