import { useEffect, useCallback, useContext, useRef } from "react";

import { OptimisticDataContext } from "../../contexts";
import { actionTypes } from "../../constants/action-types";

function useOptimisticData() {
  const [optimisticData, dispatch] = useContext(OptimisticDataContext);
  const resetDataRef = useRef(null);

  //TODO: Find out why this is being called twice and passing null in the payload (working fine for now)
  const write = useCallback(
    (payload) => {
      if (payload && Object.values(payload)?.[0] !== null) {
        dispatch({ type: actionTypes.UPDATE, payload });
      } else {
        console.error("You must pass an object with at least one key");
      }
    },
    [dispatch]
  );

  const read = useCallback(
    (key) => {
      if (!resetDataRef.current) {
        resetDataRef.current = {
          [key]: null,
        };
      }

      return optimisticData[key] || null;
    },
    [optimisticData]
  );

  useEffect(() => {
    return () => {
      if (resetDataRef.current) {
        write(resetDataRef.current);
      }
    };
  }, [write]);

  return {
    read,
    write,
  };
}

export default useOptimisticData;
