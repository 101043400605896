import React from "react";
import { Text } from "../../library/text/text";
import "./loading-page.scss";

export default function LoadingPage() {
  return (
    <div className="loading-container">
      <div className="loading-circle"></div>
      <br />
      <Text tag="body" className="loadingContent">
        Loading...
      </Text>
    </div>
  );
}
