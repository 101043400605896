import React, { useState, useCallback, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { ChildrenProp } from "../../../../shared/utils/prop-types";
import { localStorage } from "../../../../shared/utils/storage";
import { CartContext } from "../../../../shared/contexts";
import { usePrevious } from "../../../../shared/hooks/hooks";
import { isNotEmptyNullOrUndefined } from "../../../../shared/utils/validators";
import { CREATE_CART } from "../../../../shared/graphql/cart-mutations";
import {
  CART_REMOVED_ITEMS,
  CART_SUMMARY,
  CART,
  SESSION_ID,
} from "../../../../shared/constants/storage-keys";
import { GET_CART } from "../../../../shared/graphql/cart-queries";
import { formatCurrency } from "../../../../shared/utils/format";

import { v4 as uuidv4 } from "uuid";

export function getCartSummaryFromCartData(cartData) {
  return {
    id: cartData?.id || "",
    numItems: cartData?.total_quantity || 0,
    monthlyTotal: cartData?.monthly_grand_total || 0,
    grandTotal: cartData?.prices?.grand_total?.value || 0,
    hasPaymentInfo: isNotEmptyNullOrUndefined(
      cartData?.selected_payment_method?.code
    ),
  };
}

function getRemovedItem(item) {
  return {
    sku: item.product.sku,
    name: item.product.name,
    id: item.id,
  };
}

function generateMonthlyLineItems(product, plan, insurance, sim) {
  const items = [
    {
      name: product?.display_name,
      value: "edit",
    },
    product?.preorder
      ? {
          name: "Pre-Order",
          value: "",
        }
      : null,
    {
      name: plan?.display_name,
      value: formatCurrency(plan?.recurring_price),
    },
    product?.display_name !== "Bring Your Own Phone"
      ? {
          name: `${product?.configurable_options?.[1]?.value_label} - ${product?.configurable_options?.[0]?.value_label}`,
          value: null,
        }
      : null,
    insurance
      ? {
          name: insurance?.display_name,
          value: formatCurrency(insurance?.recurring_price),
        }
      : null,
    {
      name: sim?.display_name,
      value: null,
    },
    Number(product?.finance_option?.price)
      ? {
          name: product?.finance_option?.display_name,
          value: formatCurrency(product?.finance_option?.price),
        }
      : null,
    Number(product?.finance_option?.discount)
      ? {
          name: "Phone Discount",
          value: `-${formatCurrency(product?.finance_option?.discount)}`,
        }
      : null,
  ];
  return items;
}

export function cleanUpCart(cart) {
  const cartItems = cart?.items;
  let indexLineNumber = 1;
  const cartGroupByPackageId = cartItems?.reduce((acc, currentItem) => {
    const { package_id } = currentItem;
    if (package_id) {
      if (acc[package_id]) {
        acc[package_id].push(currentItem);
      } else {
        acc[package_id] = [currentItem];
      }
      if (!acc[package_id].find((obj) => obj.lineNumber)) {
        acc[package_id].push({ lineNumber: indexLineNumber });
        indexLineNumber++;
      }
    } else if (
      currentItem.product?.categories &&
      currentItem.product?.categories[0]?.canonical_url === "accessories"
    ) {
      indexLineNumber++;
    }
    return acc;
  }, {});

  let dueTodayArray = new Array();
  let cartArray = new Array();

  if (cartGroupByPackageId) {
    Object.entries(cartGroupByPackageId).forEach(function (cartItem, idx) {
      const deviceData = cartItem[1].find(
        (obj) => obj.display_product_type === "Mobile Device"
      );
      const planData = cartItem[1].find(
        (obj) => obj.display_product_type === "Mobile Plan"
      );
      const deviceInsurance = cartItem[1].find(
        (obj) => obj.display_product_type === "Device Insurance"
      );
      const tradeInKit = cartItem[1].find(
        (obj) => obj.display_product_type === "Trade-in Kit"
      );
      const simData = cartItem[1].find(
        (obj) =>
          obj.display_product_type === "SIM Card" ||
          obj.display_product_type === "eSIM"
      );

      const lineItems = generateMonthlyLineItems(
        deviceData,
        planData,
        deviceInsurance,
        simData
      );

      const additionalInfo = cartItem[1].find((obj) => obj.lineNumber);

      cartArray.push({
        lineNumber: additionalInfo.lineNumber,
        phoneName: deviceData?.display_name,
        devicePlan: planData?.display_name,
        deviceData: deviceData,
        planData: planData,
        deviceInsurance: deviceInsurance,
        simData: simData,
        cartLineItems: lineItems,
        tradeInKit: tradeInKit,
      });

      if (
        deviceData?.finance_option?.status === "0" &&
        deviceData?.display_name !== "Bring Your Own Phone"
      ) {
        dueTodayArray.push({
          name: deviceData?.display_name,
          value: formatCurrency(deviceData?.prices?.price?.value),
        });
      }

      if (simData?.prices?.price?.value > 0) {
        dueTodayArray.push({
          name: `${deviceData?.display_name} - Sim Card`,
          value: formatCurrency(simData?.prices?.price?.value),
        });
      }

      if (deviceData?.preorder === "1") {
        dueTodayArray.push({
          name: `${deviceData?.display_name} Pre-Order`,
          value: formatCurrency(planData?.recurring_price),
        });
      }
    });
  }

  return {
    id: cart.id,
    qid: cart.qid,
    numItems: cartArray?.length || 0,
    monthlyTotal: formatCurrency(cart.monthly_grand_total),
    grandTotal: formatCurrency(cart.prices.grand_total.value),
    items: cartArray,
    dueTodayItems: dueTodayArray.length ? dueTodayArray : null,
  };
}

function CartProvider(props) {
  const { children } = props;
  const [isCartInitialized, setIsCartInitialized] = useState(false);
  const [cart, setCart] = useState(null);
  const [cleanCart, setCleanCart] = useState(null);
  const [headline, setHeadline] = useState(null);
  const [isTradeInApproved, setIsTradeInApproved] = useState(false);
  const [qualificationStatus, setQualificationStatus] = useState("Not Checked");
  const prevCart = usePrevious(cart);
  const client = useApolloClient();
  const [removedItems, setRemovedItems] = useState(
    () => localStorage(CART_REMOVED_ITEMS) || []
  );

  const [cartId, setCartId] = useState(localStorage(CART_SUMMARY)?.id);

  const [cartSummary, setCartSummary] = useState(() => {
    const storedCartSummary = localStorage(CART_SUMMARY);
    return {
      numItems: storedCartSummary?.numItems || 0,
      grandTotal: storedCartSummary?.grandTotal || 0,
      monthlyTotal: storedCartSummary?.monthlyTotal || 0,
    };
  });

  const [cartProductInfoValidate, setCartProductInfoValidate] = useState(false);

  const updateQualificationStatus = (status) => {
    setQualificationStatus(status);
  };

  const updateHeadlineContext = (updatedHeadlineContent) => {
    setHeadline(updatedHeadlineContent);
  };

  const updateTradeInStatus = (status) => {
    setIsTradeInApproved(status);
  };

  const updateCartContext = useCallback((cartData) => {
    const updatedCartSummary = getCartSummaryFromCartData(cartData);
    setCart(cartData);
    setCartSummary(updatedCartSummary);
    localStorage(CART, cartData);
    localStorage(CART_SUMMARY, updatedCartSummary);
    setCleanCart(cleanUpCart(cartData));
    setCartProductInfoValidate(true);

    setCartId(cartData.id);
  }, []);

  const refreshCartContext = useCallback(async () => {
    if (cart.id) {
      try {
        const { data: cartData } = await client.query({
          query: GET_CART,
          variables: { cartId: cart.id },
        });
        updateCartContext(cartData);
        setCleanCart(cleanUpCart(cartData));
        return cartData;
      } catch (err) {
        console.error("Failed to refresh cart:", err);
      }
    }
  }, [client, updateCartContext]);

  useEffect(() => {
    let newRemovedItems = [];

    if (prevCart?.items?.length > cart?.items?.length) {
      newRemovedItems = prevCart.items.map((item) => getRemovedItem(item));
    }

    if (removedItems.length > 0) {
      setRemovedItems(removedItems);
      localStorage(CART_REMOVED_ITEMS, removedItems);
    }
  }, [prevCart, cart, removedItems]);

  useEffect(() => {
    var initCartId = localStorage(CART_SUMMARY)?.id;

    initCartId =
      initCartId && initCartId != null && initCartId.trim() != ""
        ? initCartId
        : null;

    if (initCartId != null && !cart) {
      getInitCart(initCartId);
    } else if (!initCartId) {
      createCart();
    }
  }, [client]);

  async function getInitCart(id) {
    if (id) {
      console.log("getInitCart");
      const { data } = await client.query({
        query: GET_CART,
        variables: {
          cartId: id,
        },
      });

      const cartData = data?.cart;
      updateCartContext(cartData);
      setIsCartInitialized(true);
    }
  }

  async function createCart() {
    //Create a uuid as a session identifier for services
    localStorage(SESSION_ID, uuidv4());

    const { data: cartId } = await client.mutate({
      mutation: CREATE_CART,
      fetchPolicy: "network-only",
    });

    getInitCart(cartId?.createEmptyCart);
  }

  const clearCart = () => {
    createCart();
  };

  const changeCartProductInfoValidate = (validation) => {
    setCartProductInfoValidate(validation);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartId,
        cleanCart,
        headline,
        isTradeInApproved,
        cartProductInfoValidate,
        cartSummary,
        isCartInitialized,
        removedItems,
        qualificationStatus,
        refreshCartContext,
        updateCartContext,
        updateHeadlineContext,
        updateTradeInStatus,
        clearCart,
        updateQualificationStatus,
        changeCartProductInfoValidate,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

CartProvider.propTypes = {
  children: ChildrenProp,
};

export default CartProvider;
