import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "../link/link";
import { ReactComponent as Logo } from "./logo.svg";
import { Text } from "../text/text";
import { Button } from "../button/button";
import { East } from "@mui/icons-material";
import { DropdownMenu } from "../dropdown-menu/dropdown-menu";
import useMediaQuery from "../../shared/hooks/use-media-query/use-media-query";
import { useAnalytics } from "../../shared/hooks/hooks";
import { AnalyticsContext } from "../../shared/contexts";
import "./navigation.scss";

const NAVLINKS = [
  {
    name: "Phones",
    url: "/phones",
    openNewTab: false,
  },
  {
    name: "Plans",
    url: "/offers",
    openNewTab: false,
  },
  // {
  //   name: "About",
  //   url: "/about",
  //   openNewTab: false,
  // },
  {
    name: "Support",
    url: "https://help.boostinfinite.com/",
    openNewTab: true,
  },
  {
    name: "Activate",
    url: process.env.REACT_APP_ACTIVATION_LINK,
    openNewTab: true,
  },
];

export const Navigation = () => {
  const breakpoint = useMediaQuery();
  const analytics = useAnalytics();
  const { analyticsInfo } = useContext(AnalyticsContext);

  return breakpoint.isMinSm ? (
    <div className="displayDesktop">
      <nav>
        <Link
          href="/"
          className="logo"
          onClick={() => {
            analytics.sendClickEvent(
              "Header Logo",
              "",
              location.pathname,
              analyticsInfo.pageName
            );
          }}
        >
          <Logo />
        </Link>
        <ul className="menu">
          {NAVLINKS?.map((item, idx) => (
            <NavLink
              key={idx}
              to={item.url}
              target={item.openNewTab ? "_blank" : ""}
              onClick={() => {
                analytics.sendClickEvent(
                  "Header",
                  item.name,
                  location.pathname,
                  analyticsInfo.pageName
                );
              }}
            >
              <Text tag="body" bold>
                {item.name}
              </Text>
            </NavLink>
          ))}
        </ul>
      </nav>
      <Link href={"/get-started"} className="button-link">
        <Button type="primary">
          <Text tag="body" bold>
            Get Started
          </Text>
          <East />
        </Button>
      </Link>
    </div>
  ) : (
    <div className="displayMobile">
      <DropdownMenu menuItems={NAVLINKS} />
    </div>
  );
};
