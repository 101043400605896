import React, { useState } from "react";
import { AnalyticsContext } from "../../../../shared/contexts";

function AnalyticsProvider(props) {
  const [analyticsInfo, setAnalyticsInfo] = useState({ pageName: "" });
  return (
    <AnalyticsContext.Provider value={{ analyticsInfo, setAnalyticsInfo }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}

export default AnalyticsProvider;
