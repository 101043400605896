import React from "react";
import { ApolloProvider } from "@apollo/client";
import PageRoutes from "../../routes/page-routes/page-routes";
import BreakpointInfoProvider from "./components/breakpoint-info-provider/breakpoint-info-provider";
import CartProvider from "./components/cart-provider/cart-provider";
import OptimisticDataProvider from "./components/optimistic-data-provider/optimistic-data-provider";
import client from "../../apollo/client";
import Router from "../router/router";
import { compose } from "../../shared/utils/react-utils";
import { useAsappChat } from "../../shared/hooks/hooks";
import DishCartProvider from "../../v2/features/cart-checkout/context/cart.context";
import AnalyticsProvider from "./components/analytics-provider/analytics-provider";

function App() {
  const AppProviders = compose(
    [ApolloProvider, { client }],
    BreakpointInfoProvider,
    OptimisticDataProvider,
    CartProvider,
    DishCartProvider,
    AnalyticsProvider,
  );

  useAsappChat();

  return (
    <AppProviders>
      <Router>
        <PageRoutes />
      </Router>
    </AppProviders>
  );
}

export default App;
