import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { ComposedModal, ModalBody, ModalFooter } from "@carbon/react";
import { HighlightOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "../button/button";
import { Text } from "../text/text";
import { Image } from "../image/image";
import "./modal.scss";

const Modal = ({
  isOpen = false,
  isSmall = false,
  setClose,
  headerContent,
  bodyContent,
  footerContent,
  imgUrl,
  imgAlt,
  hasCloseBtn = false,
  hasImageHeader = false,
  isCloseDisabled = false,
  ...props
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    },
    [setClose]
  );

  useEffect(() => {
    if (isOpen) {
      var el = document.querySelector(".cds--modal");

      if (el && el != null) {
        el.focus();
      }
    }

    setOpen(isOpen);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  const closeModal = () => {
    setOpen(false);
    if (setClose) {
      setClose(false);
    }
  };

  const closeBtn = (
    <div className="modal-close-btn">
      <Button type="tertiary" onClick={closeModal}>
        <Text tag="body" bold>
          Close
        </Text>
        <HighlightOff />
      </Button>
    </div>
  );

  return typeof document === "undefined"
    ? null
    : ReactDOM.createPortal(
        <div
          className={isSmall ? "small-modal-container" : "base-modal-container"}
        >
          <ComposedModal
            size="lg"
            open={open}
            onClose={closeModal}
            isFullWidth
            preventCloseOnClickOutside={isCloseDisabled}
            tabIndex={0}
            selectorPrimaryFocus="[modal-close-btn]"
            {...props}
          >
            <ModalBody>
              {hasImageHeader ? (
                <>
                  {!isCloseDisabled && (
                    <button
                      className="cds--modal-close img-close-btn"
                      title="Close"
                      aria-label="Close"
                      type="button"
                      onClick={closeModal}
                      tabIndex={1}
                    >
                      <CloseIcon />
                    </button>
                  )}

                  <div className="has-image">
                    <Image
                      src={imgUrl}
                      alt={imgAlt}
                      className="modal-header-img"
                    />
                    <div className="img-header-content">{headerContent}</div>
                  </div>
                </>
              ) : (
                <>
                  {!isCloseDisabled && (
                    <button
                      className="cds--modal-close"
                      title="Close"
                      aria-label="Close"
                      type="button"
                      onClick={closeModal}
                      tabIndex={1}
                    >
                      <CloseIcon />
                    </button>
                  )}

                  <div className="without-imgHeader-content">
                    {headerContent}
                  </div>
                </>
              )}

              {bodyContent}
            </ModalBody>
            {(footerContent || hasCloseBtn) && (
              <ModalFooter>
                {footerContent}
                {hasCloseBtn && closeBtn}
              </ModalFooter>
            )}
          </ComposedModal>
        </div>,
        document.body
      );
};

export default Modal;
