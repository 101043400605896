import React, { useState, useEffect } from "react";
import { breakpoints } from "../../../../shared/constants/breakpoints";
import { BreakpointInfoContext } from "../../../../shared/contexts";
import { ChildrenProp } from "../../../../shared/utils/prop-types";

function getBreakpointInfo(currentBreakpoint) {
  return {
    isMinXxxs: currentBreakpoint?.minWidth >= 0,
    isMinXxs: currentBreakpoint?.minWidth >= 480,
    isMinXs: currentBreakpoint?.minWidth >= 640,
    isMinSm: currentBreakpoint?.minWidth >= 768,
    isMinMd: currentBreakpoint?.minWidth >= 1024,
    isMinLg: currentBreakpoint?.minWidth >= 1200,
    isMinXl: currentBreakpoint?.minWidth >= 1920,
    ...currentBreakpoint,
  };
}
function BreakpointInfoProvider({ children }) {
  const [breakpointInfo, setBreakpointInfo] = useState({});

  function getInitialBreakpointInfo() {
    const matchedBreakpoints = breakpoints.filter(
      (breakpoint) =>
        window.matchMedia(`(min-width:${breakpoint.minWidth}px)`).matches
    );
    const currentBreakpoint = matchedBreakpoints.pop();

    return getBreakpointInfo(currentBreakpoint);
  }

  useEffect(() => {
    setBreakpointInfo(getInitialBreakpointInfo());

    const queries = Array.isArray(breakpoints)
      ? breakpoints.map((breakpoint, idx) => {
          const listener = (result) => {
            if (result.matches) {
              setBreakpointInfo(getBreakpointInfo(breakpoint));
            }
          };

          const media = breakpoints[idx + 1]
            ? window.matchMedia(
                `(min-width: ${breakpoint.minWidth}px) and (max-width:${
                  breakpoints[idx + 1].minWidth - 1
                }px)`
              )
            : window.matchMedia(`(min-width:${breakpoint.minWidth}px)`);

          media.addEventListener("change", listener);
          return {
            listener,
            media,
          };
        })
      : [];

    return () => {
      queries.forEach((query) => {
        query.media.removeEventListener("change", query.listener);
      });
    };
  }, []);

  return (
    <BreakpointInfoContext.Provider value={breakpointInfo}>
      {children}
    </BreakpointInfoContext.Provider>
  );
}

BreakpointInfoProvider.propTypes = {
  children: ChildrenProp,
};

export default BreakpointInfoProvider;
