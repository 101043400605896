import React, { useReducer } from "react";

import { ChildrenProp } from "../../../../shared/utils/prop-types";
import { OptimisticDataContext } from "../../../../shared/contexts";
import { actionTypes } from "../../../../shared/constants/action-types";

const initialState = {};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.UPDATE:
      return action.payload ? { ...state, ...action.payload } : { ...state };
    case actionTypes.RESET:
      return initialState;
    default:
      throw new Error();
  }
}

function OptimisticDataProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OptimisticDataContext.Provider value={[state, dispatch]}>
      {props.children}
    </OptimisticDataContext.Provider>
  );
}

OptimisticDataProvider.propTypes = {
  children: ChildrenProp,
};

export default OptimisticDataProvider;
