import React, { useEffect, useState } from "react";
import { Text } from "../text/text";
import PropTypes from "prop-types";
import { Checkbox as CarbonCheckbox } from "@carbon/react";
import "./checkbox.scss";

export const Checkbox = ({
  labelText,
  id = "checkbox-1",
  isDisabled = false,
  isChecked = false,
  isInvalid = false,
  invalidText,
  onClick,
  value,
  inputRef,
  ...props
}) => {
  const [checked, setChecked] = useState(isChecked);

  //Added during qual updates - checkbox never came unchecked when parent would attempt to dynamicall set to false
  //This whole logic could probably be re-written to avoid having a separate state for checked. Bandaid for now
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleChange = (e) => {
    if (e.type === "keydown") {
      e.preventDefault();
    }
    setChecked(!checked);
    if (onClick) {
      onClick(value, e);
    }
  };

  return (
    <div className="checkbox-container">
      <div className="checkbox">
        <CarbonCheckbox
          id={id}
          disabled={isDisabled}
          checked={checked}
          invalid={isInvalid}
          onClick={handleChange}
          onKeyDown={handleChange}
          value={value}
          ref={inputRef}
          {...props}
        />
        <Text tag="small">{labelText}</Text>
      </div>
      {isInvalid && (
        <Text tag="body" className="checkbox-error">
          {invalidText}
        </Text>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  labelText: PropTypes.element.isRequired,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  isInvalid: PropTypes.bool,
  invalidText: PropTypes.string,
  onChange: PropTypes.func,
};
