import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloUtil } from "../../../common/utils/ApolloUtil";
import {
  addDeviceInsurance,
  checkQualification,
  checkout,
  getCart,
  getLoanDocs,
  precheckout,
  updateShippingAddress,
} from "./queries";

const cartFetchOptions = {
  fetchOptions: {
    method: "POST",
  },
};
const useCartAPI = () => {
  const [
    executeGetCart,
    { loading: isGetCartLoading, error: getCartError, data: getCartResult },
  ] = useLazyQuery(getCart, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log(`Updating Cart Context: ${JSON.stringify(data, null, 2)}`);
    },
    onError: (error) => {
      console.log(`Error executing get cart: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  const [
    executePrecheckout,
    {
      loading: isPrecheckoutLoading,
      error: precheckoutError,
      data: precheckoutResult,
    },
  ] = useMutation(precheckout, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(`Error executing precheckout: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  const [
    executeCheckout,
    { loading: isCheckoutLoading, error: checkoutError, data: checkoutResult },
  ] = useMutation(checkout, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(`Error executing checkout: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  const [
    executeCheckQualification,
    {
      loading: isCheckQualificationLoading,
      error: checkQualificationError,
      data: checkQualificationResult,
    },
  ] = useMutation(checkQualification, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(`Error executing precheckout: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  const [
    executeGetLoanDocs,
    {
      loading: isGetLoanDocsLoading,
      error: getLoanDocsError,
      data: getLoanDocsResult,
    },
  ] = useLazyQuery(getLoanDocs, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(`Error executing get loan docs: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  const [
    executeUpdateShippingAddress,
    {
      loading: isUpdateShippingAddressLoading,
      error: updateShippingAddressError,
      data: updateShippingAddressResult,
    },
  ] = useMutation(updateShippingAddress, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(`Error executing update shipping addres: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  const [
    executeAddDeviceInsurance,
    {
      loading: isAddDeviceInsuranceLoading,
      error: addDeviceInsuranceError,
      data: addDeviceInsuranceResult,
    },
  ] = useMutation(addDeviceInsurance, {
    context: cartFetchOptions,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(`Error executing Add Device Insurance: ${error}`);
      throw ApolloUtil.processApolloError(error);
    },
  });

  return {
    checkQualificationData: {
      isLoading: isCheckQualificationLoading,
      error: ApolloUtil.processApolloError(checkQualificationError),
      result: checkQualificationResult?.ms_getQualification,
    },
    checkQualification: async (
      cartId,
      email,
      firstName,
      lastName,
      address,
      ssn
    ) => {
      delete address.id;

      var query = {
        variables: {
          cartId: cartId,
          email: email,
          firstName: firstName,
          lastName: lastName,
          addressData: address,
          ssn: ssn,
        },
      };

      console.log(
        `Executing Check Qualification with query: ${JSON.stringify(
          query,
          null,
          2
        )}`
      );

      return executeCheckQualification(query);
    },

    getCartData: {
      isLoading: isGetCartLoading,
      error: ApolloUtil.processApolloError(getCartError),
      result: getCartResult?.ms_getCart,
    },
    getCart: async (cartId) => {
      var query = {
        variables: {
          cartId: cartId,
        },
      };

      console.log(
        `Executing Get Cart with query: ${JSON.stringify(query, null, 2)}`
      );

      return executeGetCart(query);
    },

    precheckoutData: {
      isLoading: isPrecheckoutLoading,
      error: ApolloUtil.processApolloError(precheckoutError),
      result: precheckoutResult?.ms_cartPrecheckout,
    },
    precheckout: async (cartId, redirectURL) => {
      var query = {
        variables: {
          cartId: cartId,
          redirectURL: redirectURL,
        },
      };

      console.log(
        `Executing Precheckout with query: ${JSON.stringify(query, null, 2)}`
      );

      return executePrecheckout(query);
    },

    checkoutData: {
      isLoading: isCheckoutLoading,
      error: ApolloUtil.processApolloError(checkoutError),
      result: checkoutResult?.ms_cartCheckout,
    },
    checkout: async (data) => {
      var query = {
        variables: {
          input: data,
        },
      };

      console.log(
        `Executing Checkout with query: ${JSON.stringify(query, null, 2)}`
      );

      return executeCheckout(query);
    },

    getLoanDocsData: {
      isLoading: isGetLoanDocsLoading,
      error: ApolloUtil.processApolloError(getLoanDocsError),
      result: getLoanDocsResult?.ms_getLoanDocuments,
    },
    getLoanDocs: async (cartId) => {
      var query = {
        variables: {
          cartId: cartId,
        },
      };

      console.log(
        `Executing Get Loan Docs with query: ${JSON.stringify(query, null, 2)}`
      );

      return executeGetLoanDocs(query);
    },

    updateShippingAddressData: {
      isLoading: isUpdateShippingAddressLoading,
      error: ApolloUtil.processApolloError(updateShippingAddressError),
      result: updateShippingAddressResult?.ms_setShippingAddress,
    },
    updateShippingAddress: async (
      cartId,
      address,
      firstName,
      lastName,
      doPrecheckout,
      redirectURL
    ) => {
      delete address.id;

      var query = {
        variables: {
          cartId: cartId,
          addressData: address,
          firstName: firstName,
          lastName: lastName,
          doPrecheckout: doPrecheckout,
          redirectURL: redirectURL,
        },
      };

      console.log(
        `Executing Update Shipping Address with query: ${JSON.stringify(
          query,
          null,
          2
        )}`
      );

      return executeUpdateShippingAddress(query);
    },

    addDeviceInsurance: {
      isLoading: isAddDeviceInsuranceLoading,
      error: ApolloUtil.processApolloError(addDeviceInsuranceError),
      result: addDeviceInsuranceResult?.ms_addDeviceInsurance,
    },
    addDeviceInsurance: async (cartId, packageId) => {
      var query = {
        variables: {
          cartId: cartId,
          packageId: packageId,
        },
      };

      console.log(
        `Executing Add Device Insurance with query: ${JSON.stringify(
          query,
          null,
          2
        )}`
      );

      return executeAddDeviceInsurance(query);
    },
  };
};

export { useCartAPI };
