import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AnalyticsContext } from "../../shared/contexts";
import { useAnalytics, useFraud } from "../../shared/hooks/hooks";
import { MetaTags } from "../../components/aem-meta-tags/aem-meta-tags";
import useFeatureFlag from "../../shared/hooks/use-feature-flag/use-feature-flag";

const Page = (props) => {
  const { children, className } = props;
  const { analyticsInfo, setAnalyticsInfo } = useContext(AnalyticsContext);
  const analytics = useAnalytics();
  const path = location.pathname;
  const { passUserInfo } = useFraud();
  const { getFraudFlag } = useFeatureFlag();
  const fraudFlag = getFraudFlag();

  useEffect(() => {
    if (fraudFlag) {
      passUserInfo();
    }
  }, []);

  useEffect(() => {
    const pageName = path.split("/").pop();
    const analyticsHostName = "BIWeb|";
    if (path === "/") {
      setAnalyticsInfo((prevState) => ({
        ...prevState,
        pageName: analyticsHostName + "home",
      }));
    } else {
      setAnalyticsInfo((prevState) => ({
        ...prevState,
        pageName: analyticsHostName + pageName,
      }));
    }
  }, []);

  useEffect(() => {
    analytics.sendScreenLoadEvent(analyticsInfo?.pageName);
  }, [analyticsInfo.pageName]);

  return (
    <>
      <MetaTags id={path === "/" ? "home-page" : path.slice(1)} />
      <div className={className}>{children}</div>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

export default Page;
