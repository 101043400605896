import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button as CarbonButton } from "@carbon/react";
import { useAnalytics } from "../../shared/hooks/hooks";
import { AnalyticsContext } from "../../shared/contexts";
import "./button.scss";

const analytics = useAnalytics();

const getNodeText = (node) => {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join("");
  if (typeof node === "object" && node) return getNodeText(node.props.children);
};

const BUTTON_TYPES = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  ghost: "ghost",
  neutral: "neutral",
};

export const Button = ({
  isDisabled = false,
  type = "primary",
  children,
  onClick,
  ...props
}) => {
  const { analyticsInfo, setAnalyticsInfo } = useContext(AnalyticsContext);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
      analytics.sendClickEvent(
        "",
        getNodeText(children),
        location.pathname,
        analyticsInfo.pageName
      );
    }
  };
  return (
    <CarbonButton
      disabled={isDisabled}
      kind={type}
      onClick={(e) => handleClick(e)}
      {...props}
    >
      {children}
    </CarbonButton>
  );
};

CarbonButton.propTypes = {
  disabled: PropTypes.bool,
  kind: PropTypes.string,
};

Button.propTypes = {
  isDisabled: PropTypes.bool,
  type: PropTypes.oneOf([
    BUTTON_TYPES.primary,
    BUTTON_TYPES.secondary,
    BUTTON_TYPES.tertiary,
    BUTTON_TYPES.ghost,
    BUTTON_TYPES.neutral,
  ]),
};
