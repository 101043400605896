import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Navigation } from "../../library/navigation/navigation";
import { BuyflowHeaderBar } from "../../library/buyflow-header-bar/buyflow-header-bar";
import { Footer } from "../../library/footer/footer";
import "./default-layout.scss";

const DefaultLayout = (props) => {
  const {
    isBuyFlow = false,
    buyFlowHeaderText = "",
    disableNavBack,
    children,
  } = props;
  const placeholder = (
    <footer style={{ minHeight: 550, background: "#282a2b" }} />
  );

  return (
    <>
      {isBuyFlow ? (
        <BuyflowHeaderBar
          disableNavBack={disableNavBack}
          lineText={buyFlowHeaderText}
        />
      ) : (
        <Navigation />
      )}
      <main id="main">{children}</main>
      <Suspense fallback={placeholder}>
        <Footer />
      </Suspense>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default DefaultLayout;
