export const breakpoints = [
  {
    name: 'min-xxxs',
    minWidth: 0,
    isXXXS: true,
    isXXS: true,
    isXS: true,
    isSM: true,
    isMD: true,
    isLG: true,
    isXL: true
  },
  {
    name: 'min-xxs',
    minWidth: 480,
    isXXXS: false,
    isXXS: true,
    isXS: true,
    isSM: true,
    isMD: true,
    isLG: true,
    isXL: true
  },
  {
    name: 'min-xs',
    minWidth: 640,
    isXXXS: false,
    isXXS: false,
    isXS: true,
    isSM: true,
    isMD: true,
    isLG: true,
    isXL: true
  },
  {
    name: 'min-sm',
    minWidth: 768,
    isXXXS: false,
    isXXS: false,
    isXS: false,
    isSM: true,
    isMD: true,
    isLG: true,
    isXL: true
  },
  {
    name: 'min-md',
    minWidth: 1024,
    isXXXS: false,
    isXXS: false,
    isXS: false,
    isSM: false,
    isMD: true,
    isLG: true,
    isXL: true
  },
  {
    name: 'min-lg',
    minWidth: 1200,
    isXXXS: false,
    isXXS: false,
    isXS: false,
    isSM: false,
    isMD: false,
    isLG: true,
    isXL: true
  },
  {
    name: 'min-xl',
    minWidth: 1920,
    isXXXS: false,
    isXXS: false,
    isXS: false,
    isSM: false,
    isMD: false,
    isLG: false,
    isXL: true
  }
];
