import numeral from "numeral";
import { capitalize as _capitalize } from "lodash";

/**
 * Format a number or string of numbers into currency.
 * Includes $, thousands separator, and two decimal places of precision.
 *
 * @param  {Number|String} value  The value to format, e.g. 100 or "2395.44"
 * @return {String}               The number formatted for currency, e.g. "$100.00" or "$2,395.44"
 */
export function formatCurrency(value, removeDollarSign = false) {
  return removeDollarSign
    ? numeral(value).format("0,0.00")
    : numeral(value).format("$0,0.00");
}

/**
 * Format a number or string of numbers to include thousands separator.
 *
 * @param  {Number|String} value  The value to format, e.g. 5000
 * @return {String}               The formatted number, e.g. "5,000"
 */
export function formatNumber(value) {
  return numeral(value).format("0,0");
}

export function slugify(text) {
  if (!text) return "";
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/\++/g, "-plus")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .trim();
}

export function reverseSlug(text) {
  if (!text) return "";
  return titleCase(text.toString().replace(/-plus/g, "+").replace(/-/g, " "));
}

function titleCase(str) {
  return str
    .split(" ")
    .map((w) => {
      if (w === "iphone") {
        return w[0] + w[1].toUpperCase() + w.substr(2);
      } else if (w === "for") {
        return w;
      } else {
        return w[0].toUpperCase() + w.substr(1);
      }
    })
    .join(" ");
}

export function capitalizeAll(sentence) {
  if (typeof sentence !== "string") return "";

  return sentence.split(" ").map(_capitalize).join(" ");
}

export function isNumericInput(event) {
  const key = event.keyCode;
  return (key >= 48 && key <= 57) || (key >= 96 && key <= 105);
}

export function isModifierKey(event) {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 ||
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 ||
    (key > 36 && key < 41) ||
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
}

export function enforceNumberFormat(event) {
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
}

export function formatPhone(phone) {
  let formattedPhone = removePhoneFormat(phone);
  if (formattedPhone.length === 10) {
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
  } else if (formattedPhone.length > 10) {
    formattedPhone = formattedPhone.replace(
      /(\d{1,})(\d{3})(\d{3})(\d{4})/,
      "$1 ($2) $3-$4"
    );
  }
  return formattedPhone;
}

export function removePhoneFormat(phone) {
  let formattedPhone = phone || "";
  return formattedPhone.replace(/\D+/g, "");
}

export function formatSSN(value) {
  const input = value.replace(/\D/g, "");
  const first = input.substring(0, 3);
  const middle = input.substring(3, 5);
  const last = input.substring(5, 9);

  if (input.length > 5) {
    value = `${first}-${middle}-${last}`;
  } else if (input.length > 3) {
    value = `${first}-${middle}`;
  } else if (input.length > 0) {
    value = `${first}`;
  }

  return value;
}

export function formToJSON(formElem) {
  let JSONForm = {};

  if (formElem && formElem.elements.length > 0) {
    for (let field of formElem.elements) {
      if (field.name) {
        JSONForm[field.name] = field.value;
      }
    }
  }

  return JSONForm;
}

export function getCurrentTimestamp() {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getUTCDate()).padStart(2, "0");
  const hours = String(currentDate.getUTCHours()).padStart(2, "0");
  const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getUTCMilliseconds()).padStart(
    3,
    "0"
  );
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
}

export function getYearMonthDay() {
  const targetDate = new Date();
  const year = targetDate.getUTCFullYear();
  const month = String(targetDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(targetDate.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getTimestamp() {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getUTCDate()).padStart(2, "0");
  const hours = String(currentDate.getUTCHours()).padStart(2, "0");
  const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");
  // const milliseconds = String(currentDate.getUTCMilliseconds())
  //   .padStart(2, "0")
  //   .substring(0, 2);
  // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const formatIMEI = (imei) => {
  const sanitizedIMEI = imei.replace(/[^\d]/g, "");
  const formattedIMEI = sanitizedIMEI
    .slice(0, 15)
    .split("")
    .reduce((acc, digit, index) => {
      if (index === 2 || index === 6 || index === 10 || index === 14) {
        return acc + "-" + digit;
      } else {
        return acc + digit;
      }
    }, "");

  return formattedIMEI;
};
