import gql from "graphql-tag";

const configurableOptions = gql`
  fragment configurableOptions on SelectedConfigurableOption {
    option_label
    value_label
    value_id
  }
`;

const boostProtectOption = gql`
  fragment boostProtectOption on BoostProtectOption {
    status
    product_name
    product_sku
    price
  }
`;

const financeOption = gql`
  fragment financeOption on FinanceOption {
    status
    price
    display_name
    discount
  }
`;

const billingAddressFragment = gql`
  fragment billingAddressFragment on BillingCartAddress {
    firstname
    lastname
    street
    city
    postcode
    country {
      code
      label
    }
    region {
      code
      label
      region_id
    }
    telephone
  }
`;

const shippingAddressFragment = gql`
  fragment shippingAddressFragment on ShippingCartAddress {
    firstname
    lastname
    street
    city
    postcode
    country {
      code
      label
    }
    region {
      code
      label
      region_id
    }
    telephone
    selected_shipping_method {
      amount {
        currency
        value
      }
      carrier_code
      carrier_title
      method_code
      method_title
    }
  }
`;

const cartItem = gql`
  fragment cartItem on CartItemInterface {
    id
    package_id
    display_product_type
    display_name
    recurring_price
    preorder
    finance_option {
      ...financeOption
    }
    simple_product_sku
    manufacturer_data {
      option_id
      option_label
    }
    product_image
    product {
      name
      sku
      url_key
      price_range {
        minimum_price {
          final_price {
            value
            currency
          }
        }
      }
    }
    ... on ConfigurableCartItem {
      configurable_options {
        ...configurableOptions
      }
      boost_protect_option {
        ...boostProtectOption
      }
      preorder
      backorder {
        status
        backorder_qty
        backorder_estimated_ship_date
      }
    }
    quantity
    uid
    byod_device_data {
      title
      image
    }
    trade_in_data {
      imei
      offer_id
    }
  }
  ${financeOption}
  ${boostProtectOption}
  ${configurableOptions}
`;

export const cartItems = gql`
  fragment cartItems on Cart {
    id
    qid
    total_quantity
    cart_json
    items {
      id
      package_id
      display_product_type
      display_name
      preorder
      recurring_price
      finance_option {
        ...financeOption
      }
      prices {
        total_item_discount {
          value
        }
        price {
          value
        }
        discounts {
          label
          amount {
            value
          }
        }
      }
      simple_product_sku
      manufacturer_data {
        option_id
        option_label
      }
      product_image
      product {
        id
        name
        sku
        url_key
        categories {
          canonical_url
        }
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
      }
      ... on ConfigurableCartItem {
        configurable_options {
          ...configurableOptions
        }
        boost_protect_option {
          ...boostProtectOption
        }
      }
      quantity
      uid
      byod_device_data {
        title
        image
      }
      trade_in_data {
        imei
        offer_id
      }
      ...cartItem
    }
    billing_address {
      ...billingAddressFragment
    }
    shipping_addresses {
      ...shippingAddressFragment
      available_shipping_methods {
        amount {
          currency
          value
        }
        available
        carrier_code
        carrier_title
        error_message
        method_code
        method_title
        price_excl_tax {
          value
          currency
        }
        price_incl_tax {
          value
          currency
        }
      }
      selected_shipping_method {
        amount {
          value
          currency
        }
        carrier_code
        carrier_title
        method_code
        method_title
      }
    }
    available_payment_methods {
      code
      title
    }
    selected_payment_method {
      code
      title
    }
    applied_coupons {
      code
    }
    monthly_grand_total
    prices {
      grand_total {
        value
        currency
      }
      vertex_tax_details {
        total_amount
        code
        items {
          title
          amount
        }
      }
      applied_taxes {
        amount {
          value
          currency
        }
        label
      }
    }
  }

  ${cartItem}
  ${billingAddressFragment}
  ${shippingAddressFragment}
  ${financeOption}
  ${boostProtectOption}
  ${configurableOptions}
`;

export const GET_CART = gql`
  query Cart($cartId: String!) {
    cart(cart_id: $cartId) {
      ...cartItems
    }
  }
  ${cartItems}
`;

export const ADD_INSURANCE = gql`
  mutation addInsuranceInCart($package_id: Int!) {
    addInsuranceInCart(package_id: $package_id) {
      status
      message
    }
  }
`;

export const ADD_GUEST_EMAIL = gql`
  mutation addGuestEmail($cart_id: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cart_id, email: $email }) {
      cart {
        email
      }
    }
  }
`;

export const ADD_PAYMENT_METHOD_TO_CART = gql`
  mutation addPaymentMethod($cart_id: String!, $code: String!) {
    setPaymentMethodOnCart(
      input: { cart_id: $cart_id, payment_method: { code: $code } }
    ) {
      cart {
        selected_payment_method {
          code
          title
          purchase_order_number
        }
      }
    }
  }
`;
