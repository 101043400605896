import React from "react";
import { Text } from "../../library/text/text";
import { Image } from "../../library/image/image";
import { ClickableTile } from "@carbon/react";
import "./accessories-banner.scss";
import { useQuery } from "@apollo/client";
import { GET_ACCESSORIES_BANNER } from "../../shared/graphql/accessories-queries";
import useFeatureFlag from "../../shared/hooks/use-feature-flag/use-feature-flag";

export const AccessoriesBanner = ({
  alwaysPresent,
  device,
  handleEvent,
  index,
}) => {
  const { data } = useQuery(GET_ACCESSORIES_BANNER);
  const accessoriesBannerInfo = data?.aem_accessoriesBannerList.items[0];

  const { getAccessoriesFlag } = useFeatureFlag();
  const accessoriesFlag = getAccessoriesFlag();

  return (
    accessoriesBannerInfo &&
    accessoriesFlag && (
      <ClickableTile
        className={
          alwaysPresent
            ? `product-card accessories-banner ${device}-version active`
            : `product-card accessories-banner ${device}-version`
        }
        onClick={handleEvent}
        key={index}
      >
        <div className="banner-text__wrapper">
          <Text tag="h2" className="banner-title">
            {accessoriesBannerInfo.titleContent}
          </Text>
          <Text tag="body" className="accessories-link" bold>
            {accessoriesBannerInfo.linkContent}
          </Text>
        </div>
        {device === "mobile" && (
          <div className="banner-image__wrapper">
            <Image
              src={accessoriesBannerInfo.firstImage?.imageSource}
              height={"79"}
              className="product-image"
              alt={accessoriesBannerInfo.firstImage?.altText}
            />
            <Image
              src={accessoriesBannerInfo.secondImage?.imageSource}
              height={"79"}
              className="product-image"
              alt={accessoriesBannerInfo.secondImage?.altText}
            />
          </div>
        )}
        {device === "desktop" && (
          <div className="banner-image__wrapper">
            <Image
              src={accessoriesBannerInfo.firstImage?.imageSource}
              height={"215"}
              className="product-image"
              alt={accessoriesBannerInfo.firstImage?.altText}
            />
          </div>
        )}
      </ClickableTile>
    )
  );
};
