import axios from "axios";

function useSmartystreet() {

  /**
   * Execute Address Verification Hook
   *
   * @param {string} address
   */
  async function executeAddressVerification(address) {
    var result;

    try {
      result = await axios.get(
          process.env.REACT_APP_SMARTY_STREET_VERIFICATION_URL,
          {
            params: {
              key: process.env.REACT_APP_SMARTY_STREET_API_KEY,
              street: address,
            },
          }
      );

      result = result?.data;

      if (result) {
        result.forEach((entry) => {
          const { secondary_designator, secondary_number } = entry.components;

          if (
              secondary_designator &&
              secondary_designator.trim() != "" &&
              secondary_number &&
              secondary_number != ""
          ) {
            entry.delivery_line_2 = `${secondary_designator} ${secondary_number}`;

            entry.delivery_line_1 = entry.delivery_line_1.replace(
                ` ${entry.delivery_line_2}`,
                ""
            );
          }
        });
      }
    } catch (error) {
      console.log(`Error executing address validation: ${error}`);
      throw error;
    }

    return result;
  }

  return {
    executeAddressVerification
  };
}

export default useSmartystreet;
