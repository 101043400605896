import React, { useContext } from "react";
import {
  useAnalytics,
  useMediaQuery,
  useNavigateTo,
  useOptimisticData,
} from "../../../shared/hooks/hooks";
import Skeleton from "@mui/material/Skeleton";
import { isNotEmptyNullOrUndefined } from "../../../shared/utils/validators";
import { ClickableTile } from "@carbon/react";
import { Image } from "../../image/image";
import { Text } from "../../text/text";
import { formatCurrency, slugify } from "../../../shared/utils/format";
import { fixProductImage } from "../../../shared/utils/cms";
import { AnalyticsContext } from "../../../shared/contexts";
import { AccessoriesBanner } from "../../../components/accessories-banner/accessories-banner";
import "./plp-product-card.scss";

export const PlpProductCard = ({
  products,
  planName,
  isResultList,
  accessoriesBannerVisible,
  productType,
}) => {
  const optimisticData = useOptimisticData();
  const navigateTo = useNavigateTo();
  const breakpoint = useMediaQuery();
  const analytics = useAnalytics();
  const { analyticsInfo } = useContext(AnalyticsContext);

  const productCardType = productType || "phone";

  let path;

  switch (productCardType) {
    case "accessory":
      path = "accessories";
      accessoriesBannerVisible = false;
      break;
    default:
      path = "phones";
      break;
  }

  if (!isNotEmptyNullOrUndefined(products)) {
    return (
      <div className="product-card-tile-group">
        {[...Array(4).keys()].map((idx) => (
          <Skeleton
            key={idx}
            variant="rectangular"
            animation="wave"
            width={295}
            height={290}
          />
        ))}
      </div>
    );
  }

  function handleClick(product) {
    optimisticData.write({ product });
    analytics.sendClickEvent(
      `${productCardType} PLP Card`,
      product?.name + " " + "product card",
      location.pathname,
      analyticsInfo.pageName
    );
    if (planName) {
      navigateTo(`/${path}/${product.url_key}?plan=${slugify(planName)}`, {
        sku: product.sku,
      });
    } else {
      navigateTo(`/${path}/${product.url_key}`, {
        sku: product.sku,
      });
    }
  }

  function accessoriesBanner(device, index) {
    return (
      <AccessoriesBanner
        device={device}
        index={`accessories_banner_${device}` + index}
        handleEvent={() => navigateTo("/accessories")}
      />
    );
  }

  const productLength = products.length;

  const position = breakpoint.isMinMd
    ? productLength < 4 || productLength % 4 <= 1
          ? productLength - 1
          : productLength - (productLength % 4)
    : productLength > 4
          ? 3
          : productLength - 1;

  const device = breakpoint.isMinMd ? "desktop" : "mobile";

  const productCardTileGroupContent = (product, index) => {
    let prod;
    if (planName) {
      prod = {
        name: product.product_name,
        sku: product.product_sku,
        manufacturer_data: { option_label: product.manufacturer },
        small_image: {
          url: product.thumbnail,
          alt: `${product.product_name} Thumbnail`,
          title: `${product.product_name} Thumbnail`,
        },
        price_range: {
          minimum_price: {
            final_price: { value: product.product_price },
          },
        },
        isPreOrder: false,
      };
    } else {
      prod = product;
    }

    const { name, sku, manufacturer_data, small_image, price_range } = prod;

    var isPreOrder = false;

    if (product?.device_preorder && product?.device_preorder.length > 0) {
      const {
        status,
        ship_date,
        button_text,
        note,
        backorder_status,
        backorder_qty,
        backorder_estimated_ship_date,
      } = product.device_preorder[0];

      var isBackOrder =
        backorder_status === "1" &&
        backorder_qty <= 0 &&
        backorder_estimated_ship_date != null &&
        backorder_estimated_ship_date.trim() !== "";

      isPreOrder = status === 1 && !isBackOrder;
    }

    return (
      <>
        <ClickableTile
          tabIndex={0}
          key={sku}
          id={sku}
          className="product-card"
          onClick={() => handleClick(product)}
        >
          <div>
            <Image
              src={`${fixProductImage(small_image?.url)}`}
              alt={small_image?.label}
              height={breakpoint.isMinMd ? "215" : "107"}
              title={small_image?.label}
              className="product-image"
              fallbackImage={"/images/phone-fallback.svg"}
            />
            <div className="phone-details">
              <Text tag="body">{manufacturer_data?.option_label}</Text>
              <Text tag="body" bold>
                {name}
              </Text>
            </div>
          </div>
          <div>
            <div className="action-btn">
              <Text tag="body" bold>
                {isPreOrder ? 'Pre-Order Now' : 'View Details'}
              </Text>
            </div>
            <div className="pricing">
              {!planName && (
                <div className="price">
                  {price_range?.minimum_price?.final_price?.value !==
                    price_range?.minimum_price?.regular_price?.value && (
                    <Text tag="body" bold={isResultList}>
                      <del>
                        {formatCurrency(
                          price_range?.minimum_price?.regular_price?.value
                        )}
                      </del>
                    </Text>
                  )}
                  <Text tag="body" bold={isResultList}>
                    {formatCurrency(
                      price_range?.minimum_price?.final_price?.value
                    )}
                  </Text>
                </div>
              )}
              {!isResultList && (
                <div className="price">
                  {price_range?.minimum_price?.final_price?.value !==
                    price_range?.minimum_price?.regular_price?.value &&
                    !planName && (
                      <Text tag="body" bold>
                        <del>
                          {formatCurrency(
                            price_range?.minimum_price?.regular_price?.value /
                              36
                          )}
                          /mo.
                        </del>
                      </Text>
                    )}
                  <Text tag="body" bold>
                    {planName &&
                      price_range?.minimum_price?.final_price?.value > 0 &&
                      "+"}
                    {productCardType !== "accessory" &&
                        (price_range?.minimum_price?.final_price?.value > 0
                        ? `${formatCurrency(
                            planName
                                ? price_range?.minimum_price?.final_price?.value
                                : price_range?.minimum_price?.final_price?.value /
                                36
                        )}/mo.`
                        : "Included")}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </ClickableTile>
        {accessoriesBannerVisible &&
          index === position &&
          accessoriesBanner(device, index)}
      </>
    );
  };
  return (
    <div className="product-card-tile-group">
      {products &&
        products?.map((product, index) => {
          return productCardTileGroupContent(product, index);
        })}
    </div>
  );
};
