import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { GET_META_TAGS } from "../../shared/graphql/aem-meta-tags-queries";
import { isNotEmptyNullOrUndefined } from "../../shared/utils/validators";

export const MetaTags = ({ id }) => {
  if (!isNotEmptyNullOrUndefined(id)) {
    return null;
  }

  const { data } = useQuery(GET_META_TAGS, {
    variables: { id: id },
  });
  const metaData = data?.aem_metadataModelList?.items[0];

  return (
    <Helmet>
      {metaData?.title && <title>{metaData?.title}</title>}
      {metaData?.description?.plaintext && (
        <meta name="description" content={metaData?.description?.plaintext} />
      )}
      {metaData?.noIndex && <meta name="robots" content="noindex" />}
      {metaData?.noFollow && <meta name="robots" content="nofollow" />}
      {metaData?.vanityUrls &&
        metaData?.vanityUrls
          .split(",")
          .map((url, idx) => (
            <link key={`vanityUrl-${idx}`} rel="alternate" href={url.trim()} />
          ))}
      {metaData?.canonical && (
        <link rel="canonical" href={metaData?.canonical} />
      )}
    </Helmet>
  );
};
