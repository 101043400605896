import React, { lazy, Suspense, useEffect } from "react";
import LoadingPage from "../../pages/loading-page/loading-page";
import PageErrorBoundary from "./components/page-error-boundary";
import * as pages from "./page-routes-imports";
import { Route, Routes, useLocation } from "react-router";
import { RouteConfig } from "../../v2/routes/route.config";
import AccessoriesResultsPage from "../../pages/accessories-results-page/accessories-results-page";
import useFeatureFlag from "../../shared/hooks/use-feature-flag/use-feature-flag";

const AboutPage = lazy(pages.AboutPage);
const AccessoriesPlpPage = lazy(pages.AccessoriesPlpPage);
const AccessoryPdpPage = lazy(pages.AccessoryPdpPage);
const ChangePlanPage = lazy(pages.ChangePlanPage);
const CheckoutPage = lazy(pages.CheckoutPage);
const GetStartedPage = lazy(pages.GetStartedPage);
const HomePage = lazy(pages.HomePage);
const KeepYourNumber = lazy(pages.KeepYourNumber);
const Maintenance = lazy(pages.Maintenance);
const NotCompatible = lazy(pages.NotCompatible);
const NotFound = lazy(pages.NotFound);
const OrderSummaryPage = lazy(pages.OrderSummaryPage);
const PdpPage = lazy(pages.PdpPage);
const PhoneCompatibility = lazy(pages.PhoneCompatibility);
const PlanDetailPage = lazy(pages.PlanDetailPage);
const PlansPage = lazy(pages.PlansPage);
const PlpPage = lazy(pages.PlpPage);
const PromoPage = lazy(pages.PromoPage);
const TradeInPage = lazy(pages.TradeInPage);

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const { getAccessoriesFlag, getMaintenanceModeFlag } = useFeatureFlag();

const accessoriesFlag = getAccessoriesFlag();

const maintenanceFeatureFlag = getMaintenanceModeFlag();

function PageRoutes() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ScrollToTop />
      {maintenanceFeatureFlag ? (
        <Routes>
          <Route
            path="*"
            element={
              <PageErrorBoundary>
                <Maintenance />
              </PageErrorBoundary>
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PageErrorBoundary>
                <HomePage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/get-started"
            element={
              <PageErrorBoundary>
                <GetStartedPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/phones"
            element={
              <PageErrorBoundary>
                <PlpPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/phones/:productSlug"
            element={
              <PageErrorBoundary>
                <PdpPage />
              </PageErrorBoundary>
            }
          />

          <Route
            exact
            path="/offers"
            element={
              <PageErrorBoundary>
                <PlansPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/offers/:planName"
            element={
              <PageErrorBoundary>
                <PlanDetailPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/promo/:promoId"
            element={
              <PageErrorBoundary>
                <PromoPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/change-plan"
            element={
              <PageErrorBoundary>
                <ChangePlanPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/order-summary"
            element={
              <PageErrorBoundary>
                <OrderSummaryPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/phone-compatibility"
            element={
              <PageErrorBoundary>
                <PhoneCompatibility />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/keep-your-number"
            element={
              <PageErrorBoundary>
                <KeepYourNumber />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/not-compatible"
            element={
              <PageErrorBoundary>
                <NotCompatible />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path="/trade-in"
            element={
              <PageErrorBoundary>
                <TradeInPage />
              </PageErrorBoundary>
            }
          />

          <Route
            exact
            path="/about-us"
            element={
              <PageErrorBoundary>
                <AboutPage />
              </PageErrorBoundary>
            }
          />
          <Route
            exact
            path={RouteConfig.checkout.path}
            element={
              <PageErrorBoundary>
                <CheckoutPage />
              </PageErrorBoundary>
            }
          />

          {accessoriesFlag && (
            <Route
              path="/accessories"
              element={
                <PageErrorBoundary>
                  <AccessoriesPlpPage />
                </PageErrorBoundary>
              }
            />
          )}

          {accessoriesFlag && (
            <Route
              exact
              path="/accessories/results"
              element={
                <PageErrorBoundary>
                  <AccessoriesResultsPage />
                </PageErrorBoundary>
              }
            />
          )}

          {accessoriesFlag && (
            <Route
              exact
              path="/accessories/:productSlug"
              element={
                <PageErrorBoundary>
                  <AccessoryPdpPage />
                </PageErrorBoundary>
              }
            />
          )}

          <Route
            path="/site-maintenance"
            element={
              <PageErrorBoundary>
                <Maintenance />
              </PageErrorBoundary>
            }
          />
          <Route
            path="*"
            element={
              <PageErrorBoundary>
                <NotFound />
              </PageErrorBoundary>
            }
          />
        </Routes>
      )}
    </Suspense>
  );
}

export default PageRoutes;
