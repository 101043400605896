import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ChildrenProp } from "../../shared/utils/prop-types";

export default function Router(props) {
  return <BrowserRouter>{props.children}</BrowserRouter>;
}

Router.propTypes = {
  children: ChildrenProp,
};
