import React, { useEffect, useState } from "react";
import { FilterModal } from "../filter-modal/filter-modal";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_FILTERS } from "../../shared/graphql/product-queries";
import { Text } from "../../library/text/text";
import "./accessories-filter-modal.scss";
import { GET_ACCESSORIES_PLP } from "../../shared/graphql/accessories-queries";
import { useNavigateTo } from "../../shared/hooks/hooks";
import { useLocation } from "react-router";

function AccessoriesFilterModal({
  defaultCategoriesAdded,
  emptyCart,
  moreFilterChange,
  myDevicesFilterChange,
  myDevicesFilterList,
}) {
  const [moreFilterItems, setMoreFilterItems] = useState([]);
  const [myDevicesItems, setMyDevicesItems] = useState([]);
  const [activeMoreFiltersModal, setActiveMoreFiltersModal] = useState(false);
  const [activeMyDevicesModal, setActiveMyDevicesModal] = useState(false);

  const { data: moreFilterList } = useQuery(GET_PRODUCT_FILTERS, {
    variables: {
      categoryIdFilter: {
        eq: "",
      },
    },
  });

  const { data: accessoriesPLP } = useQuery(GET_ACCESSORIES_PLP);

  const categoryFilterCriteria = {
    attribute_code: "category_uid",
    label: "Type",
    count:
      accessoriesPLP?.aem_accessoriesPlpModelList?.items[0]?.categories.length,
    options:
      accessoriesPLP?.aem_accessoriesPlpModelList?.items[0]?.categories.map(
        (category) => {
          return {
            value: category.categoryNumber,
            label: category.categoryName,
          };
        }
      ),
  };

  const navigate = useNavigateTo();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/accessories/results") {
      location.state?.moreFilterItems &&
        setMoreFilterItems(location.state.moreFilterItems);
      location.state?.myDevicesItems &&
        setMyDevicesItems(location.state.myDevicesItems);
    }
  }, []);

  const validateCategoryFilter = (selectedItems) => {
    const selectedCategories = selectedItems?.filter((item) => {
      return item.categoryId === "category_uid"
    })
    return selectedCategories.length === 0;
  }

  const saveSelectedMoreFiltersCategories = (selectedItems) => {
    setMoreFilterItems(selectedItems);
    setActiveMoreFiltersModal(false);
    moreFilterChange(selectedItems);

    const isCategoryList = validateCategoryFilter(selectedItems);
    if (isCategoryList) {
      defaultCategoriesAdded(categoryFilterCriteria.options.map(category => category.value));
    }

    if (location.pathname !== "/accessories/results" && selectedItems.length) {
      navigate("/accessories/results", {
        moreFilterItems: selectedItems,
        defaultCategories: isCategoryList ? categoryFilterCriteria.options.map(category => category.value) : []
      });
    }
  };

  const selectCategories = (moreFilterList) => {
    const acceptedCategories = ["price", "manufacturer"];
    return moreFilterList.filter((filter) =>
      acceptedCategories.includes(filter.attribute_code)
    );
  };

  const saveSelectedMyDevicesCategories = (selectedItems) => {
    setMyDevicesItems(selectedItems);
    setActiveMyDevicesModal(false);
    myDevicesFilterChange(selectedItems);
    if (location.pathname !== "accessories/results" && selectedItems.length) {
      navigate("/accessories/results", { myDevicesItems: selectedItems });
    }
  };

  const getMoreFilterModal = () => {
    return (
      <FilterModal
        returnSelectedItems={saveSelectedMoreFiltersCategories}
        activeModal={activeMoreFiltersModal}
        onClose={() => {
          setActiveMoreFiltersModal(false);
        }}
        currentItems={moreFilterItems}
        title="Filters"
        aggregations={[
          categoryFilterCriteria,
          ...selectCategories(moreFilterList?.products.aggregations),
        ]}
      />
    );
  };

  const getMyDevicesFilterModal = () => {
    return (
      <FilterModal
        returnSelectedItems={saveSelectedMyDevicesCategories}
        activeModal={activeMyDevicesModal}
        onClose={() => {
          setActiveMyDevicesModal(false);
        }}
        currentItems={myDevicesItems}
        title="My Devices"
        description="Select devices to find compatible accessories."
        itemsWithImage
        aggregations={myDevicesFilterList}
      />
    );
  };

  const createFilterTooltip = (
    itemsToFilter,
    tooltipMessage,
    activeFunction
  ) => {
    return (
      <div className="filter-tooltip" onClick={() => activeFunction(true)}>
        <Text tag="small">
          {itemsToFilter.length > 0
            ? `${tooltipMessage} (${itemsToFilter.length})`
            : `${tooltipMessage}`}
        </Text>
      </div>
    );
  };

  return (
    <>
      <div className="filter-options__wrapper">
        <Text tag="body" bold className="filter-option">
          Filter By:
        </Text>
        <div className="filter-tooltips__wrapper">
          {!emptyCart &&
            createFilterTooltip(
              myDevicesItems,
              "My Devices",
              setActiveMyDevicesModal
            )}
          {createFilterTooltip(
            moreFilterItems,
            "More filters",
            setActiveMoreFiltersModal
          )}
        </div>
      </div>
      {activeMoreFiltersModal && getMoreFilterModal()}
      {activeMyDevicesModal && !emptyCart && getMyDevicesFilterModal()}
    </>
  );
}

export default AccessoriesFilterModal;
