import fetch from "unfetch";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import ApolloLinkTimeout from "apollo-link-timeout";
import { SESSION_ID } from "../shared/constants/storage-keys";
import { localStorage } from "../shared/utils/storage";

const isMutation = (d) => {
  return d.kind === "OperationDefinition" && d.operation === "mutation";
};

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  fetch: (url, options) => {
    url = url.replace(
      /((%0A)(%20)+)+/g,
      "%20"
    ); /*.replace(/((%20)(%7B))+|((%7B)(%20))+/g, '%7B').replace(/((%20)(%7D))+|((%7D)(%20))+/g, '%7D');*/
    return fetch(url, options);
  },
  uri: process.env.REACT_APP_GRAPHQL_ORGIN,
});

const timeoutLink = new ApolloLinkTimeout(43000);
const timeoutHttpLink = timeoutLink.concat(httpLink);

const headerLink = setContext(async (_, { headers, fetchOptions }) => {
  var obj = {
    headers: {
      ...headers,
      store: "infinite_en",
      "infinite-session-id": localStorage(SESSION_ID),
    },
    fetchOptions: fetchOptions
      ? fetchOptions
      : { method: _.query.definitions.some(isMutation) ? "POST" : "GET" },
  };

  return obj;
});

const link = ApolloLink.from([headerLink, timeoutHttpLink]);

export const client = new ApolloClient({
  ssrMode: false,
  link: link,
  cache,
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;
