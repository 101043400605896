/**
 * EVERY LOCAL STORAGE OR SESSION STORAGE KEY SHOULD EXIST HEREIN TO HELP US AVOID
 * CONFLICTS AND CONFUSION.
 */
export const CART_REMOVED_ITEMS = "CART_REMOVED_ITEMS";
export const CART_SUMMARY = "CART_SUMMARY";
export const CART = "CART";
export const DAYS_SINCE_LAST_VISIT = "DAYS_SINCE_LAST_VISIT";
export const LAST_VISIT = "LAST_VISIT";
export const SESSION_ID = "SESSION_ID";
export const SHOULD_REFRESH_USER_ON_APP_LOAD =
  "SHOULD_REFRESH_USER_ON_APP_LOAD";
export const UBF = "UBF";
