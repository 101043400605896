import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/app";
import { NR } from './nr.js';

NR.start();

const root = ReactDOM.createRoot(document.getElementById("root"));

const cybersourceScript = document.createElement("script");
cybersourceScript.src = process.env.REACT_APP_CYBERSOURCE_SCRIPT;
cybersourceScript.async = true;
document.body.appendChild(cybersourceScript);

if (process.env.REACT_APP_SITE_REQUIRE_PASSWORD === "true") {
  if (prompt("Enter password:", "") === process.env.REACT_APP_SITE_PASSWORD) {
    //Inject Adobe Launch script
    const launchScript = document.createElement("script");
    launchScript.src = process.env.REACT_APP_ADOBE_LAUNCH_SOURCE;
    launchScript.async = true;
    document.head.appendChild(launchScript);

    root.render(<App />);
  } else {
    alert("Incorrect Password. Refresh and try again.");
  }
} else {
  if (location.hostname === "localhost") {
    root.render(<App />);
  } else {
    const launchScript = document.createElement("script");
    launchScript.src = process.env.REACT_APP_ADOBE_LAUNCH_SOURCE;
    launchScript.async = true;
    document.head.appendChild(launchScript);
    root.render(<App />);
  }
}
