import { useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router";

export default function useNavigateTo() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationRef = useRef(location);
  locationRef.current = location;

  const navigateTo = useCallback(
    (newPath, state = {}, shouldReplace = false) => {
      const location = locationRef.current;
      const { pathname, search } = location;
      state.prevPage = pathname + search;

      if (newPath === -1 && /edit/.test(location?.state?.prevPage)) {
        newPath = location.state.prevPage?.split("?")[0];
        if (newPath === "/keep-your-number") {
          state.packageId = location?.state?.packageId;
          state.isEdit = location?.state?.isEdit ?? false;
          if (state.isEdit) {
            newPath = "/keep-your-number?edit=true";
          }
        }
        if (newPath === "/phone-compatibility") {
          newPath = "/keep-your-number";
        }
      }

      if (
        typeof newPath === "string" &&
        /http:\/\/|https:\/\/|www\./.test(newPath)
      ) {
        window.open(newPath, "_blank");
      } else if (`${pathname}${search}` !== newPath) {
        navigate(newPath, {
          replace: shouldReplace,
          state: state,
        });
      } else {
        navigate(location, {
          replace: true,
        });
      }
    },
    []
  );

  return navigateTo;
}
