import { gql } from "@apollo/client";

export const GET_META_TAGS = gql`
  query getMetaDataById($id: String!) {
    aem_metadataModelList(
      filter: { id: { _expressions: [{ value: $id, _operator: EQUALS }] } }
    ) {
      items {
        __typename
        id
        title
        description {
          plaintext
        }
        redirects
        vanityUrls
        noIndex
        noFollow
        canonical
      }
    }
  }
`;
