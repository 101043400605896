export const pathParams = {
  slug: ":slug",
};

export const RouteConfig = {
  /*
    home: {
        path: '/',
        id: 'HOME_PAGE'
    },
    productList: {
        path: '/phones',
        id: 'PRODUCT_LIST_PAGE'
    },
    plans: {
        path: '/plans',
        id: 'PLANS_PAGE'
    },
    plan: {
        path: '/plan/:slug',
        id: 'PLAN_PAGE'
    },
    productDetail: {
        path: `/phones/${pathParams.slug}`,
        id: 'PRODUCT_DETAIL_PAGE'
    }
    */
  checkout: {
    path: `/checkout`,
    id: "CHEKOUT_PAGE",
  },
};

export const buildParameterizedPath = (path, paramMap) => {
  Object.keys(paramMap).forEach((param) => {
    path = path.replace(param, paramMap[param]);
  });

  return path;
};
