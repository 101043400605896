import React, { useContext } from "react";
import { ReactComponent as Logo } from "./footer-images/logo.svg";
import { ReactComponent as Facebook } from "./footer-images/facebook.svg";
import { ReactComponent as Instagram } from "./footer-images/instagram.svg";
import { ReactComponent as Twitter } from "./footer-images/twitter.svg";
import { ReactComponent as TikTok } from "./footer-images/tiktok.svg";
import { ReactComponent as LinkedIn } from "./footer-images/linkedin.svg";
import { Link } from "../link/link";
import { Text } from "../text/text";
import { Image } from "../image/image";
import { useAnalytics } from "../../shared/hooks/hooks";
import { AnalyticsContext } from "../../shared/contexts";
import "./footer.scss";

const FOOTER = [
  {
    label: "Legal",
    link: "https://www.boostinfinite.com/terms",
    icon: null,
  },
  {
    label: "Privacy Policy",
    link: "https://www.boostinfinite.com/terms/privacy/policy",
    icon: null,
  },
  {
    label: "Your Privacy Choices",
    link: "https://www.boostinfinite.com/yourprivacychoices",
    icon: "https://s7d1.scene7.com/is/image/dish/California%20Privacy?$original$",
  },
  {
    label: "Do Not Contact",
    link: "https://www.boostinfinite.com/terms/do-not-contact",
    icon: null,
  },
  {
    label: "Newsroom",
    link: "https://www.boostinfinite.com/newsroom",
    icon: null,
  },
];

const SOCIAL = [
  {
    link: "https://www.facebook.com/BoostInfiniteOfficial",
    icon: <Facebook />,
  },
  {
    link: "https://www.instagram.com/boost_infinite/",
    icon: <Instagram />,
  },
  {
    link: "https://twitter.com/Boost_Infinite",
    icon: <Twitter />,
  },
  {
    link: "https://www.tiktok.com/@boostmobileofficial",
    icon: <TikTok />,
  },
  {
    link: "https://www.linkedin.com/company/boost-infinite",
    icon: <LinkedIn />,
  },
];

export const Footer = () => {
  const analytics = useAnalytics();
  const { analyticsInfo } = useContext(AnalyticsContext);
  return (
    <div className="footer-wrapper">
      <footer>
        <div className="footer-links">
          <Link
            href="/"
            onClick={() => {
              analytics.sendClickEvent(
                "Footer Logo",
                "",
                location.pathname,
                analyticsInfo.pageName
              );
            }}
          >
            <Logo />
          </Link>
          {FOOTER.map((item, idx) => (
            <Link
              key={idx}
              href={item.link}
              onClick={() => {
                analytics.sendClickEvent(
                  "Footer",
                  item.label,
                  location.pathname,
                  analyticsInfo.pageName
                );
              }}
            >
              <div className="footer-label">
                <Text tag="body" bold>
                  {item.label}
                  {item?.icon && (
                    <Image
                      src={item.icon}
                      alt={item.label}
                      title={item.label}
                      className="label-icon"
                    />
                  )}
                </Text>
              </div>
            </Link>
          ))}
        </div>
        <div className="social-and-legal">
          <div className="footer-social">
            {SOCIAL.map((item, idx) => (
              <Link key={idx} href={item.link}>
                {item.icon}
              </Link>
            ))}
          </div>
          <Text tag="body">
            © 2023 DISH Wireless L.L.C. All rights reserved.
          </Text>
        </div>
      </footer>
    </div>
  );
};
