import { createContext } from "react";
import { noop } from "lodash";

// Global context can be accessed from anywhere.
export const BreakpointInfoContext = createContext({
  isMinXxxs: true,
  isMinXxs: true,
  isMinXs: true,
  isMinSm: true,
  isMinMd: true,
  isMinLg: true,
  isMinXl: true,
});

export const CartContext = createContext({
  cart: null,
  cartId: null,
  cleanCart: null,
  cartSummary: null,
  headline: null,
  isTradeInApproved: false,
  cartProductInfoValidate: true,
  qualificationStatus: null,
  getReconciledCart: noop,
  updateCartContext: noop,
  refrechCartContext: noop,
  updateHeadlineContext: noop,
  updateTradeInStatus: noop,
  clearCart: noop,
  changeCartProductInfoValidate: noop,
});

export const AnalyticsContext = createContext({
  pageName: "",
});

export const OptimisticDataContext = createContext([null, noop]);

export const RaidContext = createContext({
  ubf: null,
  setUbf: noop,
  setUserInfo: noop,
});
