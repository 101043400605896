import { gql } from "@apollo/client";

export const GET_DEVICE_PLAN = gql`
  query getDevicePlan {
    getDevicePlan(input: { sku: "" }) {
      plan_sku
      plan_name
      price
      short_description
      selected_option {
        express_activation
      }
    }
  }
`;

export const UPDATE_BYOD_NUMBER = gql`
  mutation updateByodNumber(
    $cart_id: String!
    $package_id: String
    $phone_value: String!
    $plan_sku: String!
    $sim_type: String!
    $device_title: String
    $device_image: String
    $imei: String
  ) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cart_id
        cart_items: [
          {
            data: {
              package_id: $package_id
              quantity: 1
              sku: $plan_sku
              sim_type: $sim_type
              device_title: $device_title
              device_image: $device_image
              imei: $imei
              entered_options: [
                { uid: "express_activations", value: $phone_value }
              ]
            }
          }
        ]
      }
    ) {
      cart {
        id
        monthly_grand_total
        items {
          id
          uid
          package_id
          display_product_type
          display_name
          recurring_price
          quantity
          product_image
          finance_option {
            status
            price
            display_name
          }
          byod_device_data {
            image
            title
          }
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          product {
            name
            sku
          }
          ... on ConfigurableCartItem {
            configurable_options {
              option_label
              value_label
            }
          }
        }

        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        applied_coupons {
          code
        }
        prices {
          applied_taxes {
            amount {
              value
              currency
            }
            label
          }
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const GET_BYOD_CONTENT = gql`
  query getBYODPage {
    aem_byodModelList {
      items {
        bannerLogo
        bannerText
        checkCompatibilityText {
          plaintext
        }
        compatible {
          header
          description {
            plaintext
          }
        }
        keepYourNumber {
          header
          description {
            plaintext
          }
        }
        notCompatible {
          header
          description {
            plaintext
          }
        }
      }
    }
  }
`;

export const GET_BYOD_IMEI_CONTENT = gql`
  query getByodImeiPage {
    aem_byodImeiModelList {
      items {
        desktopImage
        mobileImage
        imageTitle
        imageSubtitle
        imageTextColor
        content {
          header
          content {
            plaintext
          }
        }
        closeButtonText
        buttonType
        closeButtonLink
      }
    }
  }
`;
