export const ApolloUtil = {
  processApolloError: (error) => {
    var result = error;

    if (error) {
      if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        const graphqlError = error.graphQLErrors[0];

        if (graphqlError.extensions?.responseJson) {
          const { error } = graphqlError.extensions.responseJson;

          result = graphqlError.extensions.responseJson.error
            ? graphqlError.extensions.responseJson.error
            : graphqlError.extensions.responseJson;
        }
      }

      console.log(`Apollo Error: ${JSON.stringify(result, null, 2)}`);
    }

    return result;
  },
};
