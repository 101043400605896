import React, { Fragment, useEffect, useState } from "react";
import { Text } from "../../library/text/text";
import { Button } from "../../library/button/button";
import Modal from "../../library/modal/modal";
import { FilterCriteria } from "../filter-criteria/filter-criteria";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import "./filter-modal.scss";

export const FilterModal = ({
  activeModal,
  aggregations,
  currentItems,
  description,
  itemsWithImage,
  onClose,
  returnSelectedItems,
  title,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [resetData, setResetData] = useState(false);

  useEffect(() => {
    if (currentItems.length > 0) {
      setSelectedItems(currentItems);
    }
  }, [currentItems]);

  const applyChanges = () => {
    setResetData(false);
    setSelectedItems([]);
    returnSelectedItems(selectedItems);
  };

  const clearContent = () => {
    setResetData(true);
    setSelectedItems([]);
  };

  const onInputChange = (checked, value, categoryId, isRadio) => {
    if (checked) {
      setSelectedItems(() => {
        return [...selectedItems, { value: value, categoryId: categoryId }];
      });
    } else if (isRadio) {
      const preSelectedRadio = selectedItems.filter((item) => item.isRadio);

      if (preSelectedRadio.length > 0 && preSelectedRadio[0].value === value) {
        setSelectedItems(() => {
          return [...selectedItems.filter((item) => !item.isRadio)];
        });
      } else {
        setSelectedItems(() => {
          return [
            ...selectedItems.filter((item) => !item.isRadio),
            { value: value, categoryId: categoryId, isRadio: isRadio },
          ];
        });
      }
    } else {
      setSelectedItems(() => {
        return [
          ...selectedItems.filter((item) => {
            return item.value !== value;
          }),
        ];
      });
    }
  };

  const closeModal = (event) => {
    setSelectedItems([]);
    setResetData(false);
    onClose();
  };

  const headerContent = () => {
    return (
      <div className="filter-modal__header">
        <div className="header__wrapper">
          {title && (
            <Text tag="h1" className="title">
              {title}
            </Text>
          )}
          {description && (
            <Text tag="body" className="description">
              {description}
            </Text>
          )}
        </div>
        <CloseIcon className="cross-icon__close" onClick={closeModal} />
      </div>
    );
  };

  const sendSortedBrands = (brands) => {
    return {
      ...brands,
      options: [...brands.options].sort((firstBrand, secondBrand) => {
        if (firstBrand.label < secondBrand.label) {
          return -1;
        }
        if (firstBrand.label > secondBrand.label) {
          return 1;
        }
        return 0;
      }),
    };
  };

  const bodyContent = () => {
    return (
      <div className="filter-modal__content">
        {aggregations.map((aggregation, index) => {
          return (
            <FilterCriteria
              data={
                aggregation.attribute_code?.trim().toLowerCase() ===
                "manufacturer"
                  ? sendSortedBrands(aggregation)
                  : aggregation
              }
              onInputChange={onInputChange}
              key={index}
              resetFilter={resetData}
              allowItemsWithImages={itemsWithImage}
              selectedItems={selectedItems}
            />
          );
        })}
      </div>
    );
  };

  const footerContent = () => {
    return (
      <div className="filter-modal__buttons">
        <Button className="apply-button" onClick={applyChanges}>
          <Text tag="body" bold>
            Apply
          </Text>
          <ArrowForwardIcon />
        </Button>
        <Button
          className="reset-button"
          type="secondary"
          onClick={clearContent}
        >
          <Text tag="body" bold>
            Reset
          </Text>
        </Button>
      </div>
    );
  };

  return (
    <Fragment>
      <Modal
        isOpen={activeModal}
        headerContent={headerContent()}
        bodyContent={bodyContent()}
        footerContent={footerContent()}
        setClose={onClose}
        isCloseDisabled
        className="filter-modal"
        isSmall
        isFullWidth
      />
    </Fragment>
  );
};
