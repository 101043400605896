import React, { Fragment, useState } from "react";
import "./filter-criteria.scss";
import { FilterCheckbox } from "../filter-checkbox/filter-checkbox";
import { Text } from "../../library/text/text";
import { Image } from "../../library/image/image";
import { RadioButton, RadioButtonGroup } from "@carbon/react";

export const FilterCriteria = ({
  allowItemsWithImages,
  data,
  onInputChange,
  resetFilter,
  selectedItems,
}) => {
  const [displayAllElements, setDisplayAllElements] = useState(false);
  const isLongListElement = data.count > 5;

  const handleInputChange = (e, name, categoryId, isRadio) => {
    onInputChange(e.target.checked, name, categoryId, isRadio);
  };

  const validateCheck = (name) => {
    const elementFound = selectedItems.find(
      (element) => element.value === name
    );
    return !!elementFound;
  };

  const createFilterCriteriaItem = (element, id) => {
    return (
      <li className="filter-criteria__item" key={data.attribute_code + id}>
        <FilterCheckbox
          handleInputChange={handleInputChange}
          name={element.value}
          id={data.attribute_code + id}
          categoryId={data.attribute_code}
          reset={resetFilter}
          checked={validateCheck(element.value)}
          label={element.label}
        />
      </li>
    );
  };

  const radioGroupWithToggleOption = () => {
    const radioGroupSelection = selectedItems.filter((item) => item.isRadio);
    return (
      <Fragment>
        <RadioButtonGroup
          name="controlled-radio-buttons-group"
          onChange={(e) =>
            onInputChange(
              undefined,
              e.target._wrapperState.initialValue,
              "price",
              true
            )
          }
          orientation="vertical"
        >
          {data.options
            .slice(0, displayAllElements ? data.count : 5)
            .map((element, id) => {
              const priceRangeParts = element.label.split("-");
              return (
                <div
                  key={id}
                  className="radio-button__element filter-criteria__item"
                >
                  <RadioButton
                    value={element.value}
                    label={element.value}
                    className=""
                    checked={element.value === radioGroupSelection[0]?.value}
                    labelText={""}
                  />
                  <Text tag="body">{"$" + priceRangeParts.join("-$")}</Text>
                </div>
              );
            })}
        </RadioButtonGroup>
        <li
          className="toggle-option__item"
          onClick={() =>
            setDisplayAllElements((displayAllElements) => !displayAllElements)
          }
        >
          <Text tag="small">
            {displayAllElements ? "Show Less" : "Show More"}
          </Text>
        </li>
      </Fragment>
    );
  };

  const radioGroup = () => {
    const radioGroupSelection = selectedItems.filter((item) => item.isRadio);

    return (
      <RadioButtonGroup
        name="controlled-radio-buttons-group"
        orientation="vertical"
      >
        {data.options.map((element, id) => {
          const priceRangeParts = element.label.split("-");
          return (
            <div
              key={id}
              className="radio-button__element filter-criteria__item"
            >
              <RadioButton
                value={element.value}
                label={element.value}
                className=""
                checked={element.value === radioGroupSelection[0]?.value}
                labelText={""}
                onClick={(e) =>
                  onInputChange(
                    undefined,
                    e.target._wrapperState.initialValue,
                    "price",
                    true
                  )
                }
              />
              <Text tag="body">{"$" + priceRangeParts.join("-$")}</Text>
            </div>
          );
        })}
      </RadioButtonGroup>
    );
  };

  const elementsWithToggleOption = () => {
    return (
      <Fragment>
        {data.options
          .slice(0, displayAllElements ? data.count : 5)
          .map((element, id) => {
            return createFilterCriteriaItem(element, id);
          })}
        <li
          className="toggle-option__item"
          onClick={() =>
            setDisplayAllElements((displayAllElements) => !displayAllElements)
          }
        >
          <Text tag="small">
            {displayAllElements ? "Show Less" : "Show More"}
          </Text>
        </li>
      </Fragment>
    );
  };

  const completeElementsList = () => {
    return (
      <Fragment>
        {data.options.map((element, id) => {
          return createFilterCriteriaItem(element, id);
        })}
      </Fragment>
    );
  };

  const getFilterCriteriaContentLists = () => {
    const isPriceFilterList =
      data.attribute_code?.trim().toLowerCase() === "price";
    return (
      <Fragment>
        <Text tag="body" bold className="filter-criteria__name">
          {data.label}
        </Text>
        <ul className="filter-criteria__items-wrapper">
          {isLongListElement &&
            !isPriceFilterList &&
            elementsWithToggleOption()}
          {!isLongListElement && !isPriceFilterList && completeElementsList()}
          {isLongListElement &&
            isPriceFilterList &&
            radioGroupWithToggleOption()}
          {!isLongListElement && isPriceFilterList && radioGroup()}
        </ul>
      </Fragment>
    );
  };

  const getItemWithImageContent = () => {
    return (
      <Fragment>
        <div className="device-content">
          <div className="image__wrapper">
            <Image
              src={data.product_image}
              height={"42"}
              className="product-image"
              fallbackImage={"/images/phone-fallback.svg"}
            />
          </div>
          <Text tag="body">{data.product.name}</Text>
        </div>
        <FilterCheckbox
          handleInputChange={handleInputChange}
          name={`${data.product.id}`}
          id={`${data.product.id}`}
          reset={resetFilter}
          categoryId="accessory_compatible_device"
          checked={validateCheck(`${data.product.id}`)}
        />
      </Fragment>
    );
  };

  return (
    <div
      className={
        allowItemsWithImages
          ? "filter-criteria only-item__wrapper"
          : "filter-criteria"
      }
    >
      {!allowItemsWithImages && getFilterCriteriaContentLists()}
      {allowItemsWithImages && getItemWithImageContent()}
    </div>
  );
};
