import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./image.scss";

const DOCUMENT_COMPLETE_STATE = "complete";

const useIsDocumentLoaded = () => {
  const readyState = document.readyState;
  const [isLoaded, setIsLoaded] = useState(readyState === DOCUMENT_COMPLETE_STATE);

  useEffect(() => {
    if (readyState === DOCUMENT_COMPLETE_STATE) {
      setIsLoaded(true);
    }
  }, [readyState]);

  return isLoaded;
};

export const Image = ({
  src,
  alt,
  width,
  height,
  title,
  className = "",
  shouldFadeIn = true,
  fallbackImage = "",
  ...props
}) => {
  const isDocumentLoaded = useIsDocumentLoaded();
  const [imgSrc, setImgSrc] = useState("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  useEffect(() => {
    if (isDocumentLoaded) {
      setImgSrc(src);
    }
  }, [isDocumentLoaded, src]);

  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [style, setStyle] = useState(null);
  useEffect(() => {
    setStyle(null);
    if (shouldFadeIn) {
      setStyle("img");
      if (isImgLoaded) {
        setStyle("img img--loaded");
      }
    }
  }, [shouldFadeIn, isImgLoaded]);

  return (
    <img
      className={`${style} ${className}`}
      src={imgSrc}
      alt={alt}
      title={title}
      width={width}
      height={height}
      onLoad={() => setIsImgLoaded(true)}
      onError={() => setImgSrc(fallbackImage)}
      {...props}
    ></img>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  imgHeight: PropTypes.string,
  title: PropTypes.string,
};
